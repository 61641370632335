import {ZIPUserInfoState} from "src/redux/State";
import {ZIP_LOGIN_SUCCESSFUL, ZIP_LOG_OUT_USER} from "src/constants/ActionConstants";

const initialState: ZIPUserInfoState = {
    loggedIn: false
}

interface Action {
    type: String,
}

const ZIPUserInfoReducer = (state= initialState, action: Action) => {
    switch (action.type) {
        case ZIP_LOGIN_SUCCESSFUL:
            return { ...state, loggedIn: true };
        case ZIP_LOG_OUT_USER:
            return { ...state, loggedIn: false };
        default:
            return state;
    }
}

export default ZIPUserInfoReducer;


