// ZIP
export const ZIP_TOGGLE_CONTENT_FLAG = 'ZIP_TOGGLE_CONTENT_FLAG';
export const ZIP_RECEIVE_CONTENT = 'ZIP_RECEIVE_CONTENT';
export const ZIP_APPROVE_CONTENT = 'ZIP_APPROVE_CONTENT';
export const ZIP_TOGGLE_CONTENT_STATUS = 'ZIP_TOGGLE_CONTENT_STATUS';
export const ZIP_GET_TAB_CONTENTS_COUNT = 'ZIP_GET_TAB_CONTENTS_COUNT';
export const ZIP_TOGGLE_CATEGORY_TAB = 'ZIP_TOGGLE_CATEGORY_TAB';
export const ZIP_SET_API_ERROR = 'ZIP_SET_API_ERROR';
export const ZIP_LOGIN_SUCCESSFUL = 'ZIP_LOGIN_SUCCESSFUL';
export const ZIP_LOG_OUT_USER = 'ZIP_LOG_OUT_USER'
// export const PROFILE_IMAGES = 'profileImages';
export const SET_API_STATUS = 'SET_API_STATUS';
export const ZIP_TOGGLE_PAGE_SECTIONS = 'TOGGLE_PAGE_SECTIONS';
export const ZIP_SET_API_STATUS = 'ZIP_SET_API_STATUS';
export const ZIP_UPDATE_PAYMENT_STATUS = 'ZIP_UPDATE_PAYMENT_STATUS';
export const ZIP_UPDATE_PAYMENT_FAILED_UPDATES = 'ZIP_UPDATE_PAYMENT_FAILED_UPDATES';
export const ZIP_FETCH_PAYMENT = 'ZIP_FETCH_PAYMENT';
export const ZIP_FETCH_PAYMENT_CYCLES = 'ZIP_FETCH_PAYMENT_CYCLES';

export const API_SUCCESS_CODE_200 = 200;
export const API_SUCCESS_CODE_202 = 202;

