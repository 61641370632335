export interface BIDashboardField {
    [key: string]: {
        field: string;
        allowFilter: boolean;
        allowSort: boolean;
        fieldLabelInDB: string;
    }
}

export interface BIDashboardMetadata {
    VIEW_NAME: string;
    HEADING: string;
    FOOTER: string;
    VIEW_HEADER: string;
    VIEW_LABEL: string;
    PRIMARY_COLUMN: string;
    DEFAULT_SORT_COLUMN: string;
    CAPTION: string;
}

export enum SIDE_MENU_GROUP {
    NONE,
    NIS_TOOLS,
    CATALOG_HEALTH
}

export enum SIDE_MENU_OPTION {
    NONE,
    ASIN_REDRIVE,
    SUPPLIER_FLIPPING,
    TOMBSTONING,
    STATUS_LOOKUP,
    RETAIL_MP,
    INBOUND_MP,
    MFBA_MP
}

export enum ReportType {
    SUPPLIER_FLIP = "SUPPLIER_FLIP"
}

export const CATALOG_USERS_ROLE = 'CN=zappos-catalog-ops-users,OU=Security Groups,OU=Las Vegas,OU=Zappos2010,DC=corp,DC=zappos,DC=com';
export const CATALOG_USERS_READONLY_ROLE = 'CN=zappos-catalog-ops-users-readonly,OU=Security Groups,OU=Las Vegas,OU=Zappos2010,DC=corp,DC=zappos,DC=com';

// actions
export const SET_CATALOG_USER_INFO = 'SET_CATALOG_USER_INFO';
export const SUPPLIER_FLIP_HISTORY = "SUPPLIER_FLIP_HISTORY";

export enum OperationType {
    SUPPLIER_FLIPPER = "SUPPLIER_FLIPPER",
    CATALOG_HEALTH_TOOL = "CATALOG_HEALTH_TOOL"
}

export enum OperationSubType {
    SUPPLIER_FLIPPER_HISTORY = "SUPPLIER_FLIPPER_FETCH",
    SUPPLIER_FLIPPER_INPUT = "SUPPLIER_FLIPPER_DELETE",
    FETCH_CATALOG_VIEW_DATA = "FETCH_CATALOG_VIEW_DATA",
    FETCH_CATALOG_VIEW_FILTERS = "FETCH_CATALOG_VIEW_FILTERS"
}

export enum CATALOG_HEALTH_TOOL_VIEW_NAME {
    PSS_ASIN_VIEW = "PSS_ASIN",
}

export const PSS_ASIN_SUCCESS_DASHBOARD_COLUMNS_METADATA: BIDashboardField = {
    itemType: { field: "Item Type", allowFilter: true, allowSort: false, fieldLabelInDB: "item_type" },
    vendorCode:  { field: "Vendor Code", allowFilter: true, allowSort: false, fieldLabelInDB: "vendor_code" },
    vendorName: { field: "Vendor Name", allowFilter: false, allowSort: false, fieldLabelInDB: "vendor_name" },
    pssAsin: { field: "PSS ASIN", allowFilter: false, allowSort: false, fieldLabelInDB: "asin" },
    upc: { field: "UPC", allowFilter: false, allowSort: false, fieldLabelInDB: "upc" },
    glDescription: { field: "GL Description", allowFilter: false, allowSort: false, fieldLabelInDB: "gl_description" },
    productType:{ field:  "Product Type", allowFilter: false, allowSort: false, fieldLabelInDB: "product_type" },
    productCategory: { field: "Product Category", allowFilter: false, allowSort: false, fieldLabelInDB: "product_category" },
    productSubCategory: { field: "Product Sub Category", allowFilter: false, allowSort: false, fieldLabelInDB: "product_subcategory" },
    asinCreationDate: { field: "ASIN Creation Date", allowFilter: false, allowSort: false, fieldLabelInDB: "asin_creation_date" },
    smStatus: { field: "SM Status", allowFilter: false, allowSort: false, fieldLabelInDB: "sm_status" },
    lastAvailableStatus: { field: "Last Available Status", allowFilter: true, allowSort: false, fieldLabelInDB: "last_available_status" },
    lastUpdatedDate:{ field:  "Last Updated Date", allowFilter: false, allowSort: true, fieldLabelInDB: "last_updated_date" },
    errorBucket: { field: "Error Bucket", allowFilter: false, allowSort: false, fieldLabelInDB: "error_bucket" },
    errorDescription: { field: "Error Description", allowFilter: false, allowSort: false, fieldLabelInDB: "error_description" },
}

/**
 * Keeping error dashboard metadata different since we might need to configure different filters across
 * Success View and Error View.
 */
export const PSS_ASIN_ERROR_DASHBOARD_COLUMNS_METADATA: BIDashboardField = {
    itemType: { field: "Item Type", allowFilter: true, allowSort: false, fieldLabelInDB: "item_type" },
    vendorCode:  { field: "Vendor Code", allowFilter: false, allowSort: false, fieldLabelInDB: "vendor_code" },
    vendorName: { field: "Vendor Name", allowFilter: false, allowSort: false, fieldLabelInDB: "vendor_name"  },
    pssAsin: { field: "PSS ASIN", allowFilter: false, allowSort: false, fieldLabelInDB: "asin" },
    upc: { field: "UPC", allowFilter: false, allowSort: false, fieldLabelInDB: "upc" },
    glDescription: { field: "GL Description", allowFilter: false, allowSort: false, fieldLabelInDB: "gl_description" },
    productType:{ field:  "Product Type", allowFilter: false, allowSort: false, fieldLabelInDB: "product_type" },
    productCategory: { field: "Product Category", allowFilter: false, allowSort: false, fieldLabelInDB: "product_category" },
    productSubCategory: { field: "Product Sub Category", allowFilter: false, allowSort: false, fieldLabelInDB: "product_subcategory" },
    asinCreationDate: { field: "ASIN Creation Date", allowFilter: false, allowSort: true, fieldLabelInDB: "asin_creation_date" },
    smStatus: { field: "SM Status", allowFilter: false, allowSort: false, fieldLabelInDB: "sm_status" },
    lastAvailableStatus: { field: "Last Available Status", allowFilter: false, allowSort: false, fieldLabelInDB: "last_available_status" },
    lastUpdatedDate:{ field:  "Last Updated Date", allowFilter: false, allowSort: true, fieldLabelInDB: "last_updated_date" },
    errorBucket: { field: "Error Bucket", allowFilter: true, allowSort: false, fieldLabelInDB: "error_bucket" },
    errorDescription: { field: "Error Description", allowFilter: true, allowSort: false, fieldLabelInDB: "error_description" },
}

export const PSS_ASIN_SUCCESS_DASHBOARD = {
    VIEW_NAME: CATALOG_HEALTH_TOOL_VIEW_NAME.PSS_ASIN_VIEW,
    HEADING: "PSS ASIN SUCCESS DASHBOARD",
    VIEW_HEADER: "Retail MP ASIN Status",
    VIEW_LABEL: "ASIN Success View",
    FOOTER: "Showing asins values",
    PRIMARY_COLUMN: "pssAsin",
    DEFAULT_SORT_COLUMN: "lastUpdatedDate",
    CAPTION: "Status Information for successful ASINS"
}

export const PSS_ASIN_ERROR_DASHBOARD = {
    VIEW_NAME: CATALOG_HEALTH_TOOL_VIEW_NAME.PSS_ASIN_VIEW,
    HEADING: "PSS ASIN ERROR DASHBOARD",
    VIEW_HEADER: "Retail MP ASIN Status",
    VIEW_LABEL: "ASIN Error View",
    FOOTER: "Showing asins values",
    PRIMARY_COLUMN: "pssAsin",
    DEFAULT_SORT_COLUMN: "lastUpdatedDate",
    CAPTION: "Status Information for failed/errored ASINS"
}
