// customer
export const LOOK_UP_CUSTOMER_LABEL = "Look up a customer";
export const INITIAL_CUSTOMER_ID = "ABCDE12345";

// logo
export const ZAPPOS_VIP_LOGO = 'https://m.media-amazon.com/images/G/01/zappos/loyalty/ZapposVIP_logo.png';
export const ZAPPOS_LOGO = 'https://m.media-amazon.com/images/G/01/zappos/loyalty/zappos_logo.png';
export const ZAPPOS_IN_LOGO = 'https://m.media-amazon.com/images/G/01/zappos/loyalty/Zappos_IN_LOGO.png';
export const ZAPPOS_LEGACY_VIP_LOGO = 'https://m.media-amazon.com/images/G/01/zappos/loyalty/Zappos_Legacy_Vip_Logo.png';
export const ZAPPOS_PRIME_LOGO = 'https://m.media-amazon.com/images/G/01/zappos/loyalty/Zappos_Prime_Membership_Logo.png';
export const ZAPPOS_LEGACY_VIP_LOGO_ALT = 'legacy vip logo';
export const ZAPPOS_PRIME_LOGO_ALT = 'prime logo';

// username
export const AKITA_USERNAME = "AKITA_USERNAME";

// Roles
export const AKITA_DEV_ROLE_IDENTIFIER = "CN=akita-developers,OU=Security Groups,OU=Las Vegas,OU=Zappos2010,DC=corp,DC=zappos,DC=com";
export const AKITA_CONFIG_ROLE_IDENTIFIER = "CN=akita-config,OU=Akita (zappos rewards),OU=Applications,OU=Zappos2010,DC=corp,DC=zappos,DC=com";
export const AKITA_DEV_ROLE = "AKITA_DEV_ROLE";
export const AKITA_CONFIG_ROLE = "AKITA_CONFIG_ROLE";
export const AKITA_DEFAULT_ROLE = "AKITA_DEFAULT_ROLE";

// Table Types
export const BRAND_PROMOTION_TABLE_MOCK = 'BRAND_PROMOTION_TABLE_MOCK';
export const PROMOTION_TABLE_MOCK = 'PROMOTION_TABLE_MOCK';
export const ORDER_PROMOTION_TABLE_MOCK = 'ORDER_PROMOTION_TABLE_MOCK';
export const TURNOVER_TABLE_MOCK = 'TURNOVER_TABLE_MOCK';
export const TRANSACTION_TABLE_MOCK = 'TRANSACTION_TABLE_MOCK';
export const TARGETED_PROMOTIONS = 'TARGETED_PROMOTIONS';

export const POINTS_CONVERSION_FACTOR = 10;

export const MS_PER_DAY = 86400000; // 1000 * 60 * 60 * 24

// api delay
export const API_CALL_DELAY = 4000;
export const TRANSACTIONS_UPDATE_TIME = 200;
export const TRANSACTIONS_INITIAL_LOAD_TIME = 1000;

// errors
export const API_FAILURE_EXPIRED_TOKEN = 'Authentication failed for the request. Authentication Token is expired. Please refresh the page.';
export const BRAND_PROMOTION_EDIT_DATE_VALIDATION = "Start date cannot be changed for a promotion that has already started";
export const INVALID_CUSTOMER_ID = "INVALID CUSTOMER ID";

// time zones
export const LOS_ANGELES_TIMEZONE = 'America/Los_Angeles';
export const UTC_TIMEZONE = 'UTC';

export const REQUIRED_HEADER_COLUMNS = ['customer_id', 'start_date_epoch_ms', 'end_date_epoch_ms'];

// users who should have access to Revamp Functionalities (Dev)
export const USERS_WITH_REVAMP_DEV_ACCESS = ["ayuskhan-amzn", "shivdwaj-amzn", "muskanjh-amzn", "pranayms-amzn", "sagameht-amzn", "mdpande-amzn", "shrypat-amzn", "shaunakv-amzn", "shatrun-amzn", "poopowar-amzn", "pawish-amzn", "tulsans-amzn", "dekag-amzn"];

