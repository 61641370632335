import { combineReducers } from '@reduxjs/toolkit';
import ZIPContentReducer from "src/redux/reducers/ZIPContentReducer";
import ZIPUserInfoReducer from "src/redux/reducers/ZIPUserInfoReducer";
import AkitaUserInfoReducer from "src/redux/reducers/AkitaUserInfoReducer";
import AkitaCustomerInfoReducer from "src/redux/reducers/AkitaCustomerReducer";
import AkitaTablesReducer from "src/redux/reducers/AkitaTablesReducer";
import AkitaAPIReducer from "src/redux/reducers/AkitaAPIReducer";
import ZIPPaymentContentReducer from "src/redux/reducers/ZIPPaymentContentReducer";
import FXMReducer from "src/redux/reducers/FxmReducer";
import ImageryReducer from "src/redux/reducers/ImageryReducer";
import AkitaRevampTablesReducer from "src/redux/reducers/AkitaRevampTablesReducer";
import AkitaRevampOperationsAPIReducer from "src/redux/reducers/AkitaRevampOperationsAPIReducer";
import OutfitsReducer from "src/redux/reducers/OutfitsReducer";
import CatalogReducer from "src/redux/reducers/CatalogReducer";

const RootReducer = combineReducers({
    // ZIP Reducers
    content: ZIPContentReducer,
    zipUserInfo: ZIPUserInfoReducer,
    zipPaymentContent: ZIPPaymentContentReducer,

    // Akita Reducers
    akitaUserInfo: AkitaUserInfoReducer,
    akitaCustomer: AkitaCustomerInfoReducer,
    akitaTable: AkitaTablesReducer,
    akitaAPI: AkitaAPIReducer,
    akitaRevampTable: AkitaRevampTablesReducer,
    akitaRevampOperationsAPI: AkitaRevampOperationsAPIReducer,

    //Fxm Reducers
    fxmReducer: FXMReducer,

    //Imagery Reducers
    imagery: ImageryReducer,

    //Outfit Reducers
    outfits: OutfitsReducer,

    //Catalog Reducers
    catalog: CatalogReducer
})

export default RootReducer

export type RootState = ReturnType<typeof RootReducer>
