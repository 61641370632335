// Enums
import {AkitaCustomerState} from "src/redux/State";

export enum PromoCategory {
    ACTIVE = 'active',
    UPCOMING = 'upcoming',
    ALL = 'all'
}

export enum APIStatus {
    UNKNOWN,
    FETCHING,
    FETCHED,
    FAILED,
    FAILED_401
}

export enum AkitaAPIType {
    AKITA_GET_CUSTOMER_DETAILS_API,
    AKITA_GET_BRAND_PROMOTIONS_API,
    AKITA_GET_ORDER_PROMOTIONS_API,
    AKITA_GET_PROMOTIONS_API,
    AKITA_GET_TURNOVER_API,
    AKITA_POST_BRAND_PROMOTIONS_API,
    AKITA_DELETE_BRAND_PROMOTIONS_API,
    AKITA_GET_CUSTOMER_OPERATIONS_API,
    AKITA_GET_FILE_UPLOAD_API
}

export enum AkitaRevampAPIType {
    AKITA_REVAMP_CUSTOMER_SNAPSHOTS_API,
    AKITA_REVAMP_AWARDED_BADGES_API,
    AKITA_REVAMP_ALL_AWARDED_BADGES_API,
    AKITA_REVAMP_CLT_AWARD_API,
    AKITA_REVAMP_REWARDS_API,
    AKITA_REVAMP_BADGE_PROMOS_GET_API,
    AKITA_REVAMP_ALL_BADGE_PROMOS_GET_API,
}

// Interfaces
export interface AkitaCustomerDetailsResponse {
    data: AkitaCustomerState;
}

export interface OrderPromotionsResponse {
    data: AkitaOrderPromo[];
}

export interface BrandPromotionsResponse {
    data: AkitaBrandPromo[];
}

export interface PromotionsResponse {
    data: AkitaCustomerPromotionsTarget[];
}

export interface TurnoverReviewResponse {
    data: AkitaTurnoverReview[];
}

export interface OrderPromoTables {
    [PromoCategory.ALL]: AkitaOrderPromo[];
    [PromoCategory.ACTIVE]: AkitaOrderPromo[];
    [PromoCategory.UPCOMING]: AkitaOrderPromo[];
}

export interface OrderPromoTablesAPIStatus {
    [PromoCategory.ALL]: APIStatus;
    [PromoCategory.ACTIVE]: APIStatus;
    [PromoCategory.UPCOMING]: APIStatus;
}

export interface BrandPromoTables {
    [PromoCategory.ACTIVE]: AkitaBrandPromo[];
    [PromoCategory.ALL]: AkitaBrandPromo[];
}

export interface BrandPromoTablesAPIStatus {
    [PromoCategory.ACTIVE]: APIStatus;
    [PromoCategory.ALL]: APIStatus;
}

export interface PromoTables {
    [PromoCategory.ACTIVE]: AkitaCustomerPromotionsTarget[];
    [PromoCategory.ALL]: AkitaCustomerPromotionsTarget[];
}

export interface PromoTablesAPIStatus {
    [PromoCategory.ACTIVE]: APIStatus;
    [PromoCategory.ALL]: APIStatus;
}

export interface AkitaSegment {
    segmentId?: string;
    description?: string;
    createdBy?: string | undefined;
    createdAt?: number
}

export interface AkitaBrand {
    id?: string;
    name?: string
}

export interface AkitaTransaction {
    description?: string;
    spendPoints?: number;
    spendPointsDollarValue?: number;
    spendPointsBalance?: number;
    spendPointsBalanceDollarValue?: number;
    status?: string;
    transactionId?: string;
    transactionType?: string;
    processedAtUnixMs?: number
}

interface AkitaOrderPromoBenefit {
    multiplier?: number;
    on?: string
}

export interface AkitaCustomerPromotionsTarget {
    promoId?: string;
    transactionType?: string;
    spendPoints?: number;
    tierPoints?: number;
    expiration?: number;
    autoRedeem?: boolean;
    description?: string;
    couponDays?: number;
    petsReferenceId?: string;
    ignoreConsented?: boolean;
    sendEmail?: boolean;
}

export interface AkitaCustomerSegmentsTarget {
    segmentId?: string;
    description?: string;
}

export interface AkitaCustomerTargetPromotions {
    targetType: 'segment' | 'promo';
    key: string;
    promoTarget?: AkitaCustomerPromotionsTarget;
    segmentTarget?: AkitaCustomerSegmentsTarget;
}

export interface AkitaCustomerTargetPromotionsResponse {
    customerTargets: AkitaCustomerTargetPromotions[];
}

export interface AkitaBrandPromo {
    brandId?: string;
    brandName?: string;
    brandPromoId?: string;
    startDatetime?: number;
    endDatetime?: number;
    multiplier?: number;
    segmentId?: string;
}

export interface AkitaOrderPromo {
    orderPromoId?: string;
    startDateTime?: number;
    endDateTime?: number;
    description?: string;
    customerDescription?: string;
    itemConnector?: string;
    orderConnector?: string;
    customerConnector?: string;
    itemConditions?: ItemCondition[];
    orderConditions?: OrderCondition[];
    customerConditions?: CustomerCondition[];
    pointMultiplierBenefit?: AkitaOrderPromoBenefit
}

export interface CustomerCondition {
    value?: boolean,
    type?: string,
    segmentId?: string,
    comparator?: string
}

export interface OrderCondition {
    value?: number,
    type?: string,
    comparator?: string
}

export interface OrderHistoryCondition {
    value?: number;
    type?: string;
    comparator?: string;
    timeRange?: number;
}

export interface ItemCondition {
    value?: string,
    type?: string,
    comparator?: string
}

export interface AkitaTurnoverReview {
    customerId?: string;
    manualTurnoverReviewProgramYear?: number;
    spendPoints?: number
}

export interface AkitaTransaction {
    description?: string;
    spendPoints?: number;
    spendPointsBalance?: number;
    spendPointsBalanceDollarValue?: number;
    status?: string;
    transactionId?: string;
    transactionType?: string;
    processedAtUnixMs?: number;
}

export interface AkitaRedemption {
    dollarValue?: string;
    spendPoints?: number;
}

export interface AkitaRedeemPoints {
    customerId?: string;
    dollarValue?: string;
    spendPoints?: number;
    redemptionIncrements?: AkitaRedemption[];
}

export interface AkitaAwardPointsRequest {
    reason?: string;
    spendPoints?: number;
    createdBy?: string
}

export interface AkitaCustomerStateUpdateRequest {
    flagType?: string;
    flagValue?: boolean;
    createdBy?: string
}

export interface AkitaAwardPointsResponse {
    transactionType?: string;
    transactionId?: string;
    unixTimestamp?: number;
    uri?: string
}

export interface AkitaCustomerStateUpdateResponse {
    transactionType?: string;
    transactionId?: string;
    unixTimestamp?: number;
    uri?: string
}

export interface AkitaCustomerPerks {
    refundOnFirstScan?: boolean;
    upsPickup?: boolean;
    degradedAnswer?: boolean;
}

export interface BrandPromoPayload {
    brandId: string;
    segmentId: string | undefined;
    startDatetime: number,
    endDatetime: number
    multiplier: number,
    brandPromoId?: string
}

// VIP Revamp

export interface CustomerSnapshot {
    customerId: string;
    timestamp?: number;
    banned?: boolean;
    enrolled?: boolean;
    consented?: boolean;
    vipRevampConsented?: boolean;
    legacyVip?: boolean;
    employee?: boolean;
    prime?: boolean;
    qa?: boolean;
}

export interface Badge {
    customerId?: string;
    awardId?: string;
    badgePromoId?: string;
    awardDatetime?: number;
    expirationDatetime?: number;
    programYear?: number;
    pawsPromoId?: string;
}

export interface BadgeExpiration {
    expirationType?: string;
    timeSpec?: number;
}

export interface BadgePromo {
    badgePromoId?: string;
    badgeName?: string;
    description?: string;
    startDatetime?: number;
    endDatetime?: number;
    triggerEventTypes?: string[];
    customerConditions?: CustomerCondition[];
    itemConditions?: ItemCondition[];
    orderConditions?: OrderCondition[];
    orderHistoryConditions?: OrderHistoryCondition[];
    customerConditionsConnector?: string;
    itemConditionsConnector?: string;
    orderConditionsConnector?: string;
    orderHistoryConditionsConnector?: string;
    badgeImageUrl?: string;
    isCoreProgramBadge?: boolean;
    badgeAwardType?: string;
    badgeExpiration?: BadgeExpiration;
    pawsPromoId?: string;
}

export interface Reward {
    customerId?: string;
    rewardId?: string;
    promoTrackingId?: string;
    badgePromoId?: string;
    rewardStatus?: string;
    pawsPromoId?: string;
    rewardDatetime?: number;
    pendingRewardDatetime?: number;
    redeemedRewardDatetime?: number;
    usedRewardDatetime?: number;
    expirationDatetime?: number;
    programYear?: number;
}
