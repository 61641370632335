import {AkitaCustomerState} from "src/redux/State";
import {
    AKITA_AUTO_REDEEM_FLAG,
    AKITA_BANNED_FLAG,
    AKITA_CUSTOMER_DETAILS,
    AKITA_EMPLOYEE_FLAG,
    AKITA_QA_FLAG
} from "src/constants/AkitaDashboard/actionConstants";
import {INITIAL_CUSTOMER_ID} from "src/constants/AkitaDashboard/common";

const initialState: AkitaCustomerState = {
    customerId: INITIAL_CUSTOMER_ID,
    enrolled: true,
    consented: true,
    canEnroll: false,
    enrolledSince: 100000000,
    spendPoints: 100,
    spendPointsDollarValue: 10,
    prime: true,
    legacyVip: true,
    banned: true,
    employee: true,
    qa: true,
    rofsPreferred: true,
    autoRedeem: true,
    promoPointAnnualTotal: 100,
    statsYear: 2000
}

interface Action {
    type?: string;
    customerDetails: {
        customerId: string;
        enrolled: boolean;
        consented: boolean;
        canEnroll: boolean;
        enrolledSince: number;
        spendPoints: number;
        spendPointsDollarValue: number;
        prime: boolean;
        legacyVip: boolean;
        banned: boolean;
        employee: boolean;
        qa: boolean;
        rofsPreferred: boolean;
        autoRedeem: boolean;
        promoPointAnnualTotal: number;
        statsYear: number;
        flagValue: boolean;
    }
}

const AkitaCustomerInfoReducer = (state= initialState, action: Action) => {
    const details = action.customerDetails;
    switch (action.type) {
        case AKITA_CUSTOMER_DETAILS:
            return {
                ...state,
                customerId: details?.customerId,
                enrolled: details?.enrolled,
                canEnroll: details?.canEnroll,
                autoRedeem: details?.autoRedeem,
                banned: details?.banned,
                consented: details?.consented,
                enrolledSince: details?.enrolledSince,
                spendPoints: details?.spendPoints,
                spendPointsDollarValue: details?.spendPointsDollarValue,
                prime: details?.prime,
                legacyVip: details?.legacyVip,
                employee: details?.employee,
                qa: details?.qa,
                promoPointAnnualTotal: details?.promoPointAnnualTotal,
                statsYear: details?.statsYear,
                rofsPreferred: details?.rofsPreferred
            };
        case AKITA_EMPLOYEE_FLAG:
            return {
                ...state,
                employee: details?.flagValue
            }
        case AKITA_QA_FLAG:
            return {
                ...state,
                qa: details?.flagValue
            }
        case AKITA_BANNED_FLAG:
            return {
                ...state,
                banned: details?.flagValue
            }
        case AKITA_AUTO_REDEEM_FLAG:
            return {
                ...state,
                autoRedeem: details?.flagValue
            }
        default:
            return state;
    }
}
export default AkitaCustomerInfoReducer;
