import {AkitaRevampAPIState} from "src/redux/State";
import {AkitaRevampAPIType, APIStatus} from "src/types/Akita";

export const initialState: AkitaRevampAPIState = {
    customerSnapshotAPIStatus: APIStatus.UNKNOWN,
    awardedBadgesAPIStatus: APIStatus.UNKNOWN,
    allAwardedBadgesAPIStatus: APIStatus.UNKNOWN,
    rewardsAPIStatus: APIStatus.UNKNOWN,
    cltAwardAPIStatus: APIStatus.UNKNOWN,
    badgePromosAPIStatus: APIStatus.UNKNOWN,
    allBadgePromosAPIStatus: APIStatus.UNKNOWN
}

interface Action {
    type?: AkitaRevampAPIType;
    apiStatus?: APIStatus;
    revampOperationsAPIStatus?: APIStatus;
}

const AkitaRevampOperationsAPIReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case AkitaRevampAPIType.AKITA_REVAMP_CUSTOMER_SNAPSHOTS_API:
            return {
                ...state,
                customerSnapshotAPIStatus: action.apiStatus
            };
        case AkitaRevampAPIType.AKITA_REVAMP_AWARDED_BADGES_API:
            return {
                ...state,
                awardedBadgesAPIStatus: action.apiStatus
            }
        case AkitaRevampAPIType.AKITA_REVAMP_ALL_AWARDED_BADGES_API:
            return {
                ...state,
                allAwardedBadgesAPIStatus: action.apiStatus
            }
        case AkitaRevampAPIType.AKITA_REVAMP_REWARDS_API:
            return {
                ...state,
                rewardsAPIStatus: action.apiStatus
            }
        case AkitaRevampAPIType.AKITA_REVAMP_CLT_AWARD_API:
            return {
                ...state,
                cltAwardAPIStatus: action.apiStatus
            }
        case AkitaRevampAPIType.AKITA_REVAMP_BADGE_PROMOS_GET_API:
            return {
                ...state,
                badgePromosAPIStatus: action.apiStatus
            }
        case AkitaRevampAPIType.AKITA_REVAMP_ALL_BADGE_PROMOS_GET_API:
            return {
                ...state,
                allBadgePromosAPIStatus: action.apiStatus
            }
        default:
            return state;
    }
}

export default AkitaRevampOperationsAPIReducer;
