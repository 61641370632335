export const SHOPPABLE_PHOTO_TAB = 'SHOPPABLE_PHOTO_TAB';
export const SHOPPABLE_VIDEO_TAB = 'SHOPPABLE_VIDEO_TAB';
export const COLLECTIONS_TAB = 'COLLECTIONS_TAB';
export const PROFILE_IMAGES_TAB = 'PROFILE_IMAGES_TAB';
export const INFLUENCER_APPROVAL_TAB = 'INFLUENCER_APPROVAL_TAB'
/* Content Types */
export const SHOPPABLE_PHOTO = 'shoppablePhoto';
export const SHOPPABLE_VIDEO = 'shoppableVideo';
export const COLLECTIONS = 'collections';
export const COVER_IMAGES = 'coverImage';
export const PROFILE_IMAGES = 'profileImage';
export const BRAND_SHOPPABLE_PHOTO = 'brandShoppablePhoto';
export const BRAND_SHOPPABLE_VIDEO = 'brandShoppableVideo';
export const INFLUENCER_APPROVAL_DATA = 'influencerApprovalData'
export const PAYMENT = 'payment'
