export const AKITA_LOGIN_SUCCESSFUL = 'AKITA_LOGIN_SUCCESSFUL';
export const AKITA_LOG_OUT_USER = 'AKITA_LOG_OUT_USER'
export const AKITA_SET_CUSTOMER_FLAG = 'AKITA_SET_CUSTOMER_FLAG';
export const AKITA_CUSTOMER_DETAILS = 'AKITA_CUSTOMER_DETAILS';

// flags
export const AKITA_EMPLOYEE_FLAG = 'employee';
export const AKITA_QA_FLAG = 'qa';
export const AKITA_BANNED_FLAG = 'banned';
export const AKITA_AUTO_REDEEM_FLAG = 'auto_redeem';

// lists
export const AKITA_SEGMENT_LIST = 'AKITA_SEGMENT_LIST';
export const AKITA_BRAND_LIST = 'AKITA_BRAND_LIST';
export const AKITA_REDEEM_POINTS_LIST = 'AKITA_REDEEM_POINTS_LIST';
export const AKITA_TARGETED_PROMOTIONS = 'AKITA_TARGETED_PROMOTIONS';
export const AKITA_CUSTOMER_PERKS = 'AKITA_CUSTOMER_PERKS';

// tables
export const AKITA_TURNOVER_TABLE = 'AKITA_TURNOVER_TABLE';
export const AKITA_PROMOTIONS_TABLE = 'AKITA_PROMOTIONS_TABLE';
export const AKITA_BRAND_PROMOTIONS_TABLE = 'AKITA_BRAND_PROMOTIONS_TABLE';
export const AKITA_ORDER_PROMOTIONS_TABLE = 'AKITA_ORDER_PROMOTIONS_TABLE';
export const AKITA_CUSTOMER_TRANSACTIONS_TABLE = 'AKITA_CUSTOMER_TRANSACTIONS_TABLE';
export const AKITA_CUSTOMER_ANNUAL_MAXIMUMS_TABLE = 'AKITA_CUSTOMER_ANNUAL_MAXIMUMS_TABLE'
export const AKITA_TRANSACTIONS_TABLE = 'AKITA_TRANSACTIONS_TABLE';
export const AKITA_REVAMP_CUSTOMER_SNAPSHOTS_TABLE = 'AKITA_REVAMP_CUSTOMER_SNAPSHOTS_TABLE';
export const AKITA_REVAMP_AWARDED_CUSTOMER_BADGES_TABLE = 'AKITA_REVAMP_AWARDED_CUSTOMER_BADGES_TABLE';
export const AKITA_REVAMP_ALL_AWARDED_CUSTOMER_BADGES_TABLE = 'AKITA_REVAMP_ALL_AWARDED_CUSTOMER_BADGES_TABLE';
export const AKITA_REVAMP_BADGE_PROMOS_TABLE = 'AKITA_REVAMP_BADGE_PROMOS_TABLE';
export const AKITA_REVAMP_ALL_BADGE_PROMOS_TABLE = 'AKITA_REVAMP_ALL_BADGE_PROMOS_TABLE';
export const AKITA_REVAMP_REWARDS_TABLE = 'AKITA_REVAMP_REWARDS_TABLE';


