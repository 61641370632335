export interface DashboardAPIStatus {
    name: APIName,
    status: APIStatus,
    message?: string,
}

export enum APIName {
    OUTFITS_POST_REPORT_OUTFIT_RECOMMENDATIONS,
    OUTFITS_UPDATE_OUTFIT_FEEDBACK_REPORT,
    OUTFITS_FETCH_FEEDBACK_REPORTS,
    OUTFITS_FETCH_OUTFIT_RECOMMENDATIONS,
    OUTFITS_ADD_TO_OUTFIT_DENYLIST
}

export enum APIStatus {
    UNKNOWN,
    PROCESSING,
    SUCCESS,
    FAILED,
    FAILED_401
}