import {OutfitsState} from "src/redux/State";
import {
    OUTFIT_FEEDBACK_REPORTS,
    OUTFIT_RECOMMENDATIONS,
    OUTFITS_USERNAME, SET_OUTFITS_API_STATUS
} from "src/constants/outfits/OutfitsConstants";
import {Outfit, OutfitFeedbackReport} from "src/types/Outfits";
import {APIName, APIStatus, DashboardAPIStatus} from "src/types/Common";

const initialState : OutfitsState = {
    userName: "",
    reportOutfitRecommendationsAPIStatus: {
        name : APIName.OUTFITS_POST_REPORT_OUTFIT_RECOMMENDATIONS,
        status: APIStatus.UNKNOWN
    },
    getFeedbackReportsAPIStatus: {
        name : APIName.OUTFITS_FETCH_FEEDBACK_REPORTS,
        status: APIStatus.UNKNOWN
    },
    updateOutfitFeedbackReportAPIStatus: {
        name : APIName.OUTFITS_UPDATE_OUTFIT_FEEDBACK_REPORT,
        status: APIStatus.UNKNOWN
    },
    getOutfitRecommendationsAPIStatus: {
        name : APIName.OUTFITS_FETCH_OUTFIT_RECOMMENDATIONS,
        status: APIStatus.UNKNOWN
    },
    addToOutfitDenylistAPIStatus: {
            name : APIName.OUTFITS_ADD_TO_OUTFIT_DENYLIST,
            status: APIStatus.UNKNOWN
    },
    outfitFeedbackReports: [],
    outfitRecommendations: [],
}

interface Action {
    type: String,
    userName: string,
    apiStatus: DashboardAPIStatus,
    outfitFeedbackReports: OutfitFeedbackReport[],
    outfitRecommendations: Outfit[],
}

const OutfitsReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case OUTFITS_USERNAME:
            return {
                ...state,
                userName: action.userName
            };
        case OUTFIT_FEEDBACK_REPORTS:
            return {
                ...state,
                outfitFeedbackReports: action.outfitFeedbackReports,
            };
        case OUTFIT_RECOMMENDATIONS:
            return {
                ...state,
                outfitRecommendations: action.outfitRecommendations,
            };
        case SET_OUTFITS_API_STATUS :
            return OutfitsAPIReducer(state, action);
        default : return state;
    }
}

const OutfitsAPIReducer = (state = initialState, action: Action) => {
    switch (action.apiStatus.name) {
        case APIName.OUTFITS_POST_REPORT_OUTFIT_RECOMMENDATIONS:
            return {
                ...state,
                reportOutfitRecommendationsAPIStatus: action.apiStatus,
            };
        case APIName.OUTFITS_UPDATE_OUTFIT_FEEDBACK_REPORT:
            return {
                ...state,
                updateOutfitFeedbackReportAPIStatus: action.apiStatus,
            };
        case APIName.OUTFITS_FETCH_FEEDBACK_REPORTS:
            return {
                ...state,
                getFeedbackReportsAPIStatus: action.apiStatus,
            };
        case APIName.OUTFITS_FETCH_OUTFIT_RECOMMENDATIONS:
            return {
                ...state,
                getOutfitRecommendationsAPIStatus: action.apiStatus,
            };
        case APIName.OUTFITS_ADD_TO_OUTFIT_DENYLIST:
            return {
                ...state,
                addToOutfitDenylistAPIStatus: action.apiStatus,
            };
        default : return state;
    }
}

export default OutfitsReducer;
