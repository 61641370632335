import {AkitaTablesState} from "src/redux/State";
import {
    AKITA_BRAND_LIST,
    AKITA_BRAND_PROMOTIONS_TABLE,
    AKITA_CUSTOMER_ANNUAL_MAXIMUMS_TABLE,
    AKITA_CUSTOMER_PERKS,
    AKITA_CUSTOMER_TRANSACTIONS_TABLE,
    AKITA_ORDER_PROMOTIONS_TABLE,
    AKITA_PROMOTIONS_TABLE,
    AKITA_REDEEM_POINTS_LIST,
    AKITA_SEGMENT_LIST,
    AKITA_TARGETED_PROMOTIONS,
    AKITA_TRANSACTIONS_TABLE,
    AKITA_TURNOVER_TABLE
} from "src/constants/AkitaDashboard/actionConstants";
import {AkitaCustomerTargetPromotions, AkitaTransaction, BrandPromoTables, OrderPromoTables, PromoCategory, PromoTables
} from "src/types/Akita";

const initialState: AkitaTablesState = {
    turnoverTable: [],
    promotionsTable: {
        [PromoCategory.ALL]: [],
        [PromoCategory.ACTIVE]: []
    },
    brandPromotionsTable: {
        [PromoCategory.ALL]: [],
        [PromoCategory.ACTIVE]: []
    },
    orderPromotionsTables: {
        [PromoCategory.ALL]: [],
        [PromoCategory.ACTIVE]: [],
        [PromoCategory.UPCOMING]: []
    },
    customerTransactionsTable: [],
    customerAnnualMaximumsTable: [],
    brandList: [],
    segmentList: [],
    transactionsTable: [],
    targetedPromotions: [],
    redeemPointsList: {},
    perks: {},
}

interface AkitaCustomerPromotionsTarget {
    promoId?: string;
    transactionType?: string;
    spendPoints?: number;
    tierPoints?: number;
    expiration?: number;
    autoRedeem?: boolean;
    description?: string;
    couponDays?: number;
    petsReferenceId?: string;
    ignoreConsented?: boolean;
    sendEmail?: boolean;
}

interface AkitaCustomerSegmentsTarget {
    segmentId?: string;
    description?: string;
}

interface AkitaCustomerTargetPromotionsObject {
    targetType?: string;
    key?: string;
    promoTarget?: AkitaCustomerPromotionsTarget;
    segmentTarget?: AkitaCustomerSegmentsTarget;
}

interface segmentObject {
    segmentId?: string,
    description?: string,
    createdBy?: string | undefined,
    createdAt?: number
}

interface brandObject {
    id?: string,
    name?: string
}

interface AkitaOrderPromoBenefitObject {
    multiplier?: number;
    on?: string
}

interface AkitaTurnoverReview {
    customerId?: string;
    manualTurnoverReview?: number;
    spendPoints?: number
}

interface RedemptionObject {
    dollarValue?: string;
    spendPoints?: number;
}

interface AkitaRedeemPointsObject {
    customerId?: string;
    dollarValue?: string;
    spendPoints?: number;
    redemptionIncrements?: RedemptionObject[];
}

interface AkitaCustomerPerks {
    refundOnFirstScan?: boolean;
    upsPickup?: boolean;
    degradedAnswer?: boolean;
}

interface Action {
    type?: string;
    table?: object[];
    transactions?: AkitaTransaction[];
    turnoverReview?: AkitaTurnoverReview[];
    promoTable?: PromoTables;
    brandPromoTable?: BrandPromoTables;
    orderPromo?: OrderPromoTables;
    segmentList?: segmentObject[];
    brandList?: brandObject[];
    targetedPromotions?: AkitaCustomerTargetPromotions[];
    redeemPointsList?: AkitaRedeemPointsObject;
    perks?: AkitaCustomerPerks;
}

const AkitaTablesReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case AKITA_TURNOVER_TABLE:
            return {
                ...state,
                turnoverTable: action.turnoverReview
            };
        case AKITA_PROMOTIONS_TABLE:
            return {
                ...state,
                promotionsTable: action.promoTable
            };
        case AKITA_BRAND_PROMOTIONS_TABLE:
            return {
                ...state,
                brandPromotionsTable: action.brandPromoTable
            };
        case AKITA_ORDER_PROMOTIONS_TABLE:
            return {
                ...state,
                orderPromotionsTables: action.orderPromo
            };
        case AKITA_CUSTOMER_ANNUAL_MAXIMUMS_TABLE:
            return {
                ...state,
                customerAnnualMaximumsTable: action.table
            };
        case AKITA_CUSTOMER_TRANSACTIONS_TABLE:
            return {
                ...state,
                customerTransactionsTable: action.table
            };
        case AKITA_SEGMENT_LIST:
            return {
                ...state,
                segmentList: action.segmentList
            }
        case AKITA_BRAND_LIST:
            return {
                ...state,
                brandList: action.brandList
            }
        case AKITA_TRANSACTIONS_TABLE:
            return {
                ...state,
                transactionsTable: action.transactions
            }
        case AKITA_TARGETED_PROMOTIONS:
            return {
                ...state,
                targetedPromotions: action.targetedPromotions
            }
        case AKITA_REDEEM_POINTS_LIST:
            return {
                ...state,
                redeemPointsList: action.redeemPointsList
            }
        case AKITA_CUSTOMER_PERKS:
            return {
                ...state,
                perks: action.perks
            }
        default:
            return state;
    }
}
export default AkitaTablesReducer;
