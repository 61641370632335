import {CatalogAPIStatus} from "src/types/catalog/Catalog";

export enum TABS {
    SUCCESS_VIEW,
    ERROR_VIEW
}

export enum SORT_ORDER {
    ASC = "ASC",
    DESC = "DESC"
}

export enum SQL_OPERATOR {
    IN = "IN",
    EQUAL = "EQUAL",
    BETWEEN = "BETWEEN"
}

export interface FilterItem {
    [key: string]: {
        fieldName: string;
        allValues: string[];
        isSet: boolean;
        setValues: Set<string>;
    }
}

export type DashboardField = Record<string, string>;

export interface Filter {
    attributeName: string;
    value: string[];
    operator: string;
}

export interface CatalogHealthToolStateType {
    apiStatus: CatalogAPIStatus,
    data: PssAsinData[],    // ToDo: Use | to add additional record types
    filterAttributeValues: FilterValuesMap
}

export interface PssAsinData {
    itemType: string,
    vendorCode:  string,
    vendorName: string,
    pssAsin: string,
    upc: string,
    glDescription: string,
    productType: string,
    productCategory: string,
    productSubCategory: string,
    asinCreationDate: string,
    smStatus: string,
    lastAvailableStatus: string,
    lastUpdatedDate: string,
    errorBucket: string,
    errorDescription: string,
}

export type FilterValuesMap = Record<string, string[]>

export interface FetchCatalogViewDataRequest {
    viewName: string;
    isErrorView: boolean;
    limit: number;
    offset: number;
    sortBy: string;
    sortOrder: string;
    filters: Filter[];
}

export interface FetchCatalogViewFiltersRequest {
    viewName: string;
    filterAttributes: string[];
}
