export enum OutfitRecommendationModel {
    OCCASION = 'occasion',
    GENERIC = 'generic'
}

export enum OutfitDenyListIdType {
    STYLE_ID = 'STYLE_ID',
    OUTFIT_ID = 'OUTFIT_ID'
}

export interface OutfitFeedbackReports {
    outfitReportList: OutfitFeedbackReport[];
}

export interface OutfitFeedbackReport {
    id: string,
    reporter: string,
    reviewStatus: string,
    createdAt: number,
    updatedAt: number,
    seedProductUrl: string,
    seedStyleId?: string,
    reportingReason?: string
}

export interface OutfitRecommendationsResponse {
    recommendations: Outfit[]
}

export interface Outfit {
    layout: string,
    complementaryGroupId: string,
    complementaryScore: number,
    averageSellingPrice: number,
    productList: Product[]
}
export interface Product {
    styleId: string,
    asin: string,
    productName: string,
    imageId: string,
    price: number,
    productId: string,
    productType: string,
    itemType: string
    brand: string,
    currentlyViewing: boolean
}
