import ls from "localstorage-slim";
import {DashboardLocalStorageObject} from "src/types/Imagery";

export function getItemFromLocalStorage(key: string) {
    return ls.get(key);
}

export function saveItemToLocalStorage(key: string, value: Object, expiresIn?: number) {
    ls.set(key, value, { ttl: expiresIn });
}

export function removeItemFromLocalStorage(key: string) {
    ls.remove(key);
}

export function resetLocalStorage() {
    ls.clear();
}

// Local Storage Utils for Imagery

export const getPreferredColumnsFromLocalStorage = (reportType: string, defaultColumns: string[]): string[] => {
    const object = getItemFromLocalStorage(reportType) as DashboardLocalStorageObject;
    let preferredColumns: string[] = object?.preferredColumns?.length ? object.preferredColumns : defaultColumns;
    preferredColumns = preferredColumns.filter(column => defaultColumns.includes(column));
    return preferredColumns;
};
