import jwt_decode, {JwtPayload} from "jwt-decode";
import { fetchAdfsToken } from "../apis/adfs";

export type AdfsTokenResponse = {
  accessToken?: string;
  tokenType?: string;
  expiresIn?: number;
  resource?: string;
  refreshToken?: string;
  refreshTokenExpiresIn?: number;
  idToken?: string;
  expiryTimestamp?: string;
};

export async function processAuthToken(authCode: string) {

    try {
        const responsePromise = fetchAdfsToken('authorization_code', authCode);
        const [response] = await Promise.all([responsePromise]);
        if (response) {
            const decodedToken = jwt_decode<JwtPayload>(response['accessToken']);
            const expiryTimestamp = decodedToken['exp'];
            return {
                expiryTimestamp : expiryTimestamp?.toString(),
                accessToken : response['accessToken'],
                expiresIn : response['expiresIn'],
                refreshToken : response['refreshToken'],
                refreshTokenExpiresIn : response['refreshTokenExpiresIn']
            } as AdfsTokenResponse;
        } else {
            console.log('Response null/empty for adfs token api with auth code :' + authCode);
            return {};
        }
    } catch (error) {
        console.log('Error while retrieving token response for authorization_code : ' + error);
        return {};
    }
}

export async function processRefreshToken(refreshToken: string) {

    try {
        const responsePromise = fetchAdfsToken('refresh_token', refreshToken);
        const [response] = await Promise.all([responsePromise]);
        if (response) {
            const decodedToken = jwt_decode<JwtPayload>(response['accessToken']);
            const expiryTimestamp = decodedToken['exp'];
            return {
                expiryTimestamp : expiryTimestamp?.toString(),
                accessToken : response['accessToken'],
                expiresIn : response['expiresIn']
            } as AdfsTokenResponse;
        } else {
            console.log('Response null/empty for adfs token api with refresh token:' + refreshToken);
            return {};
        }
    } catch (error) {
        console.log('Error while retrieving token response for refresh_token : ' + error);
        return {};
    }
}

export async function getAdfsToken(refreshToken: string) {
    let search = new URLSearchParams(window.location.search);
    const code = search.get('code')
    if (code) {
        const tokenResponse = processAuthToken(code);
        const [tokenResult] = await Promise.all([tokenResponse]);
        return tokenResult;
    } else {
        if (refreshToken) {
            const refreshTokenResponse = processRefreshToken(refreshToken);
            const [tokenResult] = await Promise.all([refreshTokenResponse]);
            return tokenResult;
        } else {
            return {};
        }
    }
}

export function isSessionInvalid(token: string) {
    const decodedToken = jwt_decode<JwtPayload>(token);
    const decodedExp = decodedToken['exp'] || 0;
    return decodedExp < Math.floor(Date.now() / 1000);
}