import {ZIPContentState} from "src/redux/State";
import {
    SET_API_STATUS,
    ZIP_TOGGLE_PAGE_SECTIONS,
    ZIP_RECEIVE_CONTENT,
    ZIP_TOGGLE_CONTENT_STATUS,
    ZIP_TOGGLE_CONTENT_FLAG,
    ZIP_SET_API_ERROR,
    ZIP_SET_API_STATUS,
    ZIP_GET_TAB_CONTENTS_COUNT,
    ZIP_TOGGLE_CATEGORY_TAB
} from "src/constants/ActionConstants";
import {STATUS_PENDING_REVIEW} from "src/constants/InfluencerConstants";
import {
    BRAND_SHOPPABLE_PHOTO,
    BRAND_SHOPPABLE_VIDEO,
    COLLECTIONS,
    COVER_IMAGES,
    PROFILE_IMAGES,
    SHOPPABLE_PHOTO,
    SHOPPABLE_VIDEO,
    INFLUENCER_APPROVAL_DATA
} from "src/constants/PageCategoryTabsConstants";
import { INFLUENCER_CONTENT, INFLUENCER_APPROVAL } from "src/constants/ApprovalContentTabsConstants";

const initialState: ZIPContentState = {
    selectedContentSection: INFLUENCER_CONTENT,
    shoppablePosts: [],
    shoppableVideos: [],
    collections: [],
    profileImages: [],
    coverImages: [],
    countFlaggedTabContent: 0,
    countAllTabContent: 0,
    selectedPageCategory: SHOPPABLE_PHOTO,
    apiError: false,
    apiStatus: '',
    apiName: '',
    brandShoppablePosts: [],
    brandShoppableVideos: [],
    influencerApprovalData: [],
}

const getContent = (state= initialState, contentType: string) => {
    switch (contentType) {
        case SHOPPABLE_PHOTO:
            return state.shoppablePosts;
        case SHOPPABLE_VIDEO:
            return state.shoppableVideos;
        case COLLECTIONS:
            return state.collections;
        case PROFILE_IMAGES:
            return state.profileImages;
        case COVER_IMAGES:
            return state.coverImages;
        case BRAND_SHOPPABLE_PHOTO:
            return state.brandShoppablePosts;
        case BRAND_SHOPPABLE_VIDEO:
            return state.brandShoppableVideos;
    }
}

interface Action {
    type?: string;
    content?: any;
    contentId?: string;
    contentAction?: string;
    selectedTab?: string;
    contentType?: string;
    apiStatus?: string;
    apiName?: string
    selectedSection?: string;
}

const ZIPContentReducer = (state= initialState, action: Action) => {
    switch (action.type) {
        case ZIP_TOGGLE_CONTENT_STATUS:
            const allApprovalContent = getContent(state, action.contentType!);
            const toggledApprovalContent = allApprovalContent?.map((content: any) => {
                if (content.contentId === action.contentId) {
                    content.status = action.contentAction;
                }
                return content;
            });
            switch (action.contentType) {
                case SHOPPABLE_PHOTO:
                    return { ...state, shoppablePosts: toggledApprovalContent };
                case SHOPPABLE_VIDEO:
                    return { ...state, shoppableVideos: toggledApprovalContent };
                case COLLECTIONS:
                    return { ...state, collections: toggledApprovalContent };
                case PROFILE_IMAGES:
                    return { ...state, profileImages: toggledApprovalContent };
                case COVER_IMAGES:
                    return { ...state, coverImages: toggledApprovalContent };
                case BRAND_SHOPPABLE_PHOTO:
                    return { ...state, brandShoppablePosts: toggledApprovalContent };
                case BRAND_SHOPPABLE_VIDEO:
                    return { ...state, brandShoppableVideos: toggledApprovalContent };
                default:
                    return { ...state };
            }
        case ZIP_TOGGLE_CONTENT_FLAG:
            const allContent = getContent(state, action.contentType!);
            const toggledContent = allContent?.map((content: any) => {
                if (content.contentId === action.contentId) {
                    content.flagged = !content.flagged;
                }
                return content;
            });
            switch (action.contentType) {
                case SHOPPABLE_PHOTO:
                    return { ...state, shoppablePosts: toggledContent };
                case SHOPPABLE_VIDEO:
                    return { ...state, shoppableVideos: toggledContent };
                case COLLECTIONS:
                    return { ...state, collections: toggledContent };
                case PROFILE_IMAGES:
                    return { ...state, profileImages: toggledContent };
                case COVER_IMAGES:
                    return { ...state, profileCoverImages: toggledContent };
                case BRAND_SHOPPABLE_PHOTO:
                    return { ...state, brandShoppablePosts: toggledContent };
                case BRAND_SHOPPABLE_VIDEO:
                    return { ...state, brandShoppableVideos: toggledContent };
                default:
                    return { ...state };
            }
        case ZIP_RECEIVE_CONTENT:
            switch (action.contentType) {
                case SHOPPABLE_PHOTO:
                    return { ...state, shoppablePosts: action.content };
                case SHOPPABLE_VIDEO:
                    return { ...state, shoppableVideos: action.content };
                case COLLECTIONS:
                    return { ...state, collections: action.content };
                case PROFILE_IMAGES:
                    return { ...state, profileImages: action.content };
                case COVER_IMAGES:
                    return { ...state, coverImages: action.content };
                case BRAND_SHOPPABLE_PHOTO:
                    return { ...state, brandShoppablePosts: action.content };
                case BRAND_SHOPPABLE_VIDEO:
                    return { ...state, brandShoppableVideos: action.content };
                case INFLUENCER_APPROVAL_DATA:
                    return { ...state, influencerApprovalData: action.content };
                default:
                    return { ...state };
            }
        case ZIP_GET_TAB_CONTENTS_COUNT:
            const tabContent = getContent(state, action.contentType!);
            const flaggedPostsCount = tabContent?.filter((post: any) => post.flagged && post.status === STATUS_PENDING_REVIEW && post.contentType === state.selectedPageCategory).length;
            const allPostsCount = tabContent?.filter((post: any) => post.status === STATUS_PENDING_REVIEW && post.contentType === state.selectedPageCategory).length;
            return { ...state, countFlaggedTabContent: flaggedPostsCount, countAllTabContent: allPostsCount }
        case ZIP_TOGGLE_CATEGORY_TAB:
            return { ...state, selectedPageCategory: action.selectedTab }
        case ZIP_SET_API_ERROR:
            return { ...state, apiError: true };
        case SET_API_STATUS:
            return { ...state, apiStatus: action.apiStatus, apiName: action.apiName };
        case ZIP_TOGGLE_PAGE_SECTIONS:
            return { ...state, selectedContentSection: action.selectedSection };
        case ZIP_SET_API_STATUS:
            return { ...state, apiStatus: action.apiStatus, apiName: action.apiName};
        default:
            return state
    }
}

export default ZIPContentReducer;
