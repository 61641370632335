import {ZIPPaymentContentState} from "src/redux/State";
import {
    ZIP_FETCH_PAYMENT,
    ZIP_FETCH_PAYMENT_CYCLES,
    ZIP_UPDATE_PAYMENT_FAILED_UPDATES,
    ZIP_UPDATE_PAYMENT_STATUS
} from "src/constants/ActionConstants";

const initialState: ZIPPaymentContentState = {
    influencerPaymentFailedUpdates: [],
    influencerPaymentUpdateStatus: "",
    influencerPaymentDetails: { earnings: [], nextToken: "" },
    allPaymentCycles: []
}

interface Action {
    contentType: string;
    content: any;
}
const ZIPPaymentContentReducer = (state = initialState, action: Action) => {
    switch (action.contentType) {
        case ZIP_UPDATE_PAYMENT_STATUS:
            return {...state, influencerPaymentUpdateStatus: action.content};
        case ZIP_UPDATE_PAYMENT_FAILED_UPDATES:
            return {...state, influencerPaymentFailedUpdates: action.content};
        case ZIP_FETCH_PAYMENT:
            const { content: {earnings, nextToken} } = action;
            return { ...state, influencerPaymentDetails: { earnings, nextToken } };
        case ZIP_FETCH_PAYMENT_CYCLES:
            return {...state, allPaymentCycles: action.content};
        default:
            return state;
    }
}

export default ZIPPaymentContentReducer;