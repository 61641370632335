import React from 'react';
import {BrowserRouter as Router, Route, Redirect, Switch} from 'react-router-dom';

const MarketingDashboardLogin = React.lazy(() => import('src/components/ZIP/MarketingDashboardLoginPage'));
const MarketingDashboardApproval = React.lazy(() => import('src/components/ZIP/MarketingDashboardApprovalPage'));
const AkitaHome = React.lazy(() => import('src/components/Akita/AkitaHome'));
const MarketingLandingPage = React.lazy(() => import('src/components/MarketingLandingPage/MarketingLandingPage'));
const FxmLogin = React.lazy(() => import('src/components/FXM/FxmLoginPage'));
const FxmHome = React.lazy(() => import('src/components/FXM/FxmHome/FxmHome'));
const ImageryHome = React.lazy(() => import('src/components/imagery/ImageryHome'));
const CatalogHome = React.lazy(() => import('src/components/catalog/CatalogHome'));
const OutfitsHome = React.lazy(() => import('src/components/outfits/OutfitsHome'));

interface MarketingDashboardRoute {
    title: string,
    path: string,
    exact: boolean,
    component: React.ComponentType
}

const Routes: MarketingDashboardRoute[] = [
    {
        title: 'Marketing Landing Page',
        path: '/',
        exact: true,
        component: MarketingLandingPage
    },
    {
        title: 'Marketing Dashboard Login Page',
        path: '/zip',
        exact: true,
        component: MarketingDashboardLogin
    },
    {
        title: 'Marketing Dashboard Influencer Content Approval Page',
        path: '/zip/influencer/content/approval',
        exact: false,
        component: MarketingDashboardApproval
    },
    {
        title: 'Akita Home Page',
        path: '/akita',
        exact: true,
        component: AkitaHome
    },
    {
        title: 'FXM Login Page',
        path: '/fxm',
        exact: true,
        component: FxmLogin
    },
    {
        title: 'FXM Home Page',
        path: '/fxm/email',
        exact: true,
        component: FxmHome
    },
    {
        title: 'Imagery Home Page',
        path: '/imagery',
        exact: true,
        component: ImageryHome
    },
    {
        title: 'Zpider Cat',
        path: '/catalog',
        exact: true,
        component: CatalogHome
    },
    {
        title: 'Outfits Home Page',
        path: '/outfits',
        exact: true,
        component: OutfitsHome
    },
];

const MarketingDashboardRouter: React.FC<{}> = () => {
    return (
        <Router>
            <React.Suspense fallback={<div>Loading...</div>}>
                <Switch>

                    <Route exact path="/zip">
                        <Redirect to="/"/>
                    </Route>
                    <Route exact path="/zip/influencer/content/approval">
                        <Redirect to="/"/>
                    </Route>
                    <Route path="/catalog">
                        <Redirect to="/"/>
                    </Route>
                    <Route path="/outfits">
                        <Redirect to="/"/>
                    </Route>
                    {Routes.map((route) => (
                        <Route
                            key={route.title}
                            path={route.path}
                            exact={route.exact}
                            component={route.component}
                        />
                    ))}
                    <Route path="*">
                        <Redirect to="/"/>
                    </Route>
                </Switch>
            </React.Suspense>
        </Router>
    );
};

export {
    MarketingDashboardRouter
};

