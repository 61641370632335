
export enum FxmAPIType {
    FETCH_CANCEL_ORDER_FILE_UPLOAD_PRESIGNEDURL,
    PUT_CANCEL_ORDER_FILE_UPLOAD_FXM,
    PUT_CANCEL_ORDER_FILE_UPLOAD_ZFT,
    GET_CANCEL_ORDER_FILEUPLOAD_SUBMITBUTTON_ZAPPOS,
    GET_CANCEL_ORDER_FILEUPLOAD_SUBMITBUTTON_SIXPM
}

export const FXM_LOGIN_SUCCESSFUL = 'FXM_LOGIN_SUCCESSFUL';
export const FXM_LOGIN_FAILED = 'FXM_LOGIN_FAILED';

export enum APIState {
    UNKNOWN,
    FETCHING,
    FETCHED,
    FAILED
}
