import {AkitaRevampTablesState} from "src/redux/State";
import {
    AKITA_REVAMP_ALL_AWARDED_CUSTOMER_BADGES_TABLE,
    AKITA_REVAMP_ALL_BADGE_PROMOS_TABLE,
    AKITA_REVAMP_AWARDED_CUSTOMER_BADGES_TABLE,
    AKITA_REVAMP_BADGE_PROMOS_TABLE,
    AKITA_REVAMP_CUSTOMER_SNAPSHOTS_TABLE,
    AKITA_REVAMP_REWARDS_TABLE
} from "src/constants/AkitaDashboard/actionConstants";
import {Badge, BadgePromo, CustomerSnapshot, Reward} from "src/types/Akita";

export const initialState: AkitaRevampTablesState = {
    customerSnapshotsTable: [],
    awardedBadgesTable: [],
    allAwardedBadgesTable: [],
    badgePromosTable: [],
    allBadgePromosTable: [],
    rewardsTable: []
}

interface Action {
    type?: string;
    customerSnapshots?: CustomerSnapshot[];
    awardedBadges?: Badge[];
    badgePromos?: BadgePromo[];
    rewards?: Reward[];
}

const AkitaTablesReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case AKITA_REVAMP_CUSTOMER_SNAPSHOTS_TABLE:
            return {
                ...state,
                customerSnapshotsTable: action.customerSnapshots
            };
        case AKITA_REVAMP_AWARDED_CUSTOMER_BADGES_TABLE:
            return {
                ...state,
                awardedBadgesTable: action.awardedBadges
            };
        case AKITA_REVAMP_ALL_AWARDED_CUSTOMER_BADGES_TABLE:
            return {
                ...state,
                allAwardedBadgesTable: action.awardedBadges
            };
        case AKITA_REVAMP_BADGE_PROMOS_TABLE:
            return {
                ...state,
                badgePromosTable: action.badgePromos
            }
        case AKITA_REVAMP_ALL_BADGE_PROMOS_TABLE:
            return {
                ...state,
                allBadgePromosTable: action.badgePromos
            }
        case AKITA_REVAMP_REWARDS_TABLE:
            return {
                ...state,
                rewardsTable: action.rewards
            }
        default:
            return state;
    }
}
export default AkitaTablesReducer;
