import React from 'react';
import {MarketingDashboardRouter} from "src/app/router";
import CookieService from "../utils/CookieService";
import {AdfsTokenResponse, isSessionInvalid, getAdfsToken} from "../utils/Authentication";
import {AdfsCreds} from "../utils/SecretsManager";
import {fetchAdfsCredentials} from "../apis/adfs";
import {handleCookie, resetAdfsCookiesAndLocalStorage} from "src/utils/CookieUtils";

const ls = require('localstorage-slim');

export async function fetchCredentials() {
    const configPromise = fetchAdfsCredentials();
    const [config] = await Promise.all([configPromise]);
    return config;
}

export class App extends React.Component <
    {},
    { idToken: string; isAuthorized: boolean; refreshToken: string; adfsConfig: AdfsCreds; showError: boolean }
> {
    _isMounted = false;

    constructor(props: Readonly<{}>) {
        super(props);
        this.state = {
            idToken: '',
            isAuthorized: false,
            refreshToken: '',
            adfsConfig: {},
            showError: false
        };
    }

    componentDidMount = () => {
        this._isMounted = true;
        fetchCredentials().then(response => {
            const isEnabled = response['isEnabled'] == 'true';
            this.setState({
                ...this.state,
                isAuthorized: !isEnabled,
                adfsConfig: response
            });
            if (isEnabled) {
                let token, refreshToken;
                try {
                    token = CookieService.get('adfs-access-token') || ls.get('adfs-access-token');
                    refreshToken = CookieService.get('adfs-refresh-token') || ls.get('adfs-refresh-token');
                } catch {
                    this.setState({
                        ...this.state, idToken: '', isAuthorized: false, refreshToken: refreshToken,
                        showError: true
                    });
                    resetAdfsCookiesAndLocalStorage();
                }
                if (token) {
                    const invalidSession = isSessionInvalid(token);
                    if (invalidSession) {
                        this.setState({
                            ...this.state, idToken: '', isAuthorized: false, refreshToken: refreshToken,
                            showError: true
                        });
                        resetAdfsCookiesAndLocalStorage();
                    } else {
                        this.setState({...this.state, idToken: token, isAuthorized: true, refreshToken: refreshToken});
                    }
                } else {
                    this.setState({...this.state, idToken: '', isAuthorized: false, refreshToken: refreshToken});
                }
                if (!this.state.isAuthorized) {
                    this.authenticateUser();
                }
            }
        });
    };

    componentWillUnmount = () => {
        this._isMounted = false;
    };

    redirect = () => {
        window.location.href = '/';
    }

    setAdfsError = () => {
        this.setState({
            ...this.state,
            showError: true
        });
    }

    setSession = (tokenResponse: AdfsTokenResponse) => {
        if (tokenResponse) {
            try {
                const expiryTimestamp = tokenResponse['expiryTimestamp' as keyof AdfsTokenResponse] || 0;
                const expiresIn = tokenResponse['expiresIn'] || 0;
                if (expiryTimestamp == 0 || isSessionInvalid(tokenResponse['accessToken'] || '')) {
                    console.log('Invalid session details found in adfs token : ' + tokenResponse['accessToken']);
                    this.setAdfsError();
                    return;
                }
                handleCookie('adfs-access-token', tokenResponse['accessToken'] || '', expiresIn);
                if (tokenResponse['refreshToken' as keyof AdfsTokenResponse]) {
                    handleCookie('adfs-refresh-token', tokenResponse['refreshToken'] || '', tokenResponse['refreshTokenExpiresIn'] || 0);
                }
                if (tokenResponse['accessToken']) {
                    this.setState({...this.state, idToken: tokenResponse['accessToken'], isAuthorized: true});
                }
                this.redirect();
            } catch (error) {
                console.log('Error while setting active session: ' + error);
                this.setAdfsError();
            }
        } else {
            console.log('Token Response not found');
            this.setAdfsError();
        }
    }

    login = () => {
        if (!this.state.showError) {
            const adfsConfig: AdfsCreds = this.state.adfsConfig;
            window.location.href = 'https://fs.zappos.com/adfs/oauth2/authorize?client_id='
                + adfsConfig['clientId' as keyof AdfsCreds] + '&'
                + 'redirect_uri=' + adfsConfig['redirectUri' as keyof AdfsCreds] + '&'
                + 'resource=' + adfsConfig['resource' as keyof AdfsCreds] + '&'
                + 'response_type=code&response_mode=query';
        }
    }

    authenticateUser = async () => {
        getAdfsToken(this.state.refreshToken).then((token: AdfsTokenResponse) => {
            if (Object.keys(token).length !== 0) {
                this.setSession(token);
            } else {
                this.login();
            }
        });
    }

    render() {
        return (
            <>
                <main>
                    {this.state.isAuthorized ? (
                            <MarketingDashboardRouter/>
                        ) :
                        this.state.showError ? (
                                <>
                                    <h1>Oops...Something went wrong</h1>
                                    <button onClick={this.redirect}>Try again</button>
                                </>
                            ) :
                            <></>}
                </main>
            </>
        );
    }
}

export default App;