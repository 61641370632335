import {ImageryState} from "src/redux/State";
import {
    IMAGERY_USERS_ROLE, REPORT_DOWNLOAD_FAILURE,
    REPORT_DOWNLOAD_IN_PROGRESS,
    REPORT_DOWNLOAD_SUCCESSFUL,
    ReportType,
    REQUEST_RECENT_REPORTS,
    REQUEST_REPORT_DATA,
    REQUEST_REPORT_GENERATION,
    RESET_REPORT_GENERATION_REQUEST_RESULT,
    SET_IMAGERY_USER_INFO,
    SET_RECENT_REPORTS,
    SET_REPORT_DATA,
    SET_REPORT_GENERATION_REQUEST_FAILURE,
    SET_REPORT_GENERATION_REQUEST_IN_PROGRESS,
    TOGGLE_SHOW_UPDATE_COMPLETE_MODAL,
    UPDATE_REPORT_DATA
} from "src/constants/imagery/ImageryConstants";
import {
    FCReport,
    ImagePullerDetails,
    StudioNilData,
    SupplierTrackerData,
    VendorFuturePOData,
    VendorNilData
} from "src/types/Imagery";

export const initialState: ImageryState = {
    userName: '',
    userDisplayName: '',
    userRole: '',
    isAuthorisedToUpdate: false,
    isLoading: false,
    reportType: '',
    vendorNilReport: {
        reportData: [],
        lastRefreshedTimestamp: 0,
        isLoading: false,
        isUpdating: false
    },
    vendorFuturePOReport: {
        reportData: [],
        lastRefreshedTimestamp: 0,
        isLoading: false
    },
    studioNilReport: {
        reportData: [],
        lastRefreshedTimestamp: 0,
        isLoading: false,
        isUpdating: false
    },
    supplierTrackerReport: {
        reportData: [],
        lastRefreshedTimestamp: 0,
        isLoading: false,
        isUpdating: false
    },
    fcPicklist: {
        recentFCReports: [],
        isLoading: false,
        isUpdating: false,
        isReportGenerationRequestSuccessful: false,
        isReportDownloadInProgress: false
    },
    imagePullerDetails: {
        recentImagePullerReports: [],
        isLoading: false,
        isUpdating: false,
        isReportGenerationRequestSuccessful: false
    },
    statusHistoryReport: {
        isReportDownloading: false
    }
}

interface Action {
    type: string;
    reportType?: string;
    reportData?: VendorNilData[] | VendorFuturePOData[] | StudioNilData[] | SupplierTrackerData[];
    lastRefreshedTimestamp?: number;
    userDisplayName?: string;
    userName?: string;
    userRole?: string;
    successfulItems?: VendorNilData[] | SupplierTrackerData[];
    failedItems?: VendorNilData[] | SupplierTrackerData[];
    newItemStatus?: string;
    recentReports?: FCReport[];
    recentImagePullerReports?: ImagePullerDetails[];
}

const VendorNilReducer = (state: ImageryState, action: Action) => {
    const { type, reportType = '', reportData = [], lastRefreshedTimestamp = 0, successfulItems = [], failedItems = [], newItemStatus = '' } = action;

    let updatedVendorNilReport = { ...state.vendorNilReport };

    switch (type) {
        case REQUEST_REPORT_DATA:
            updatedVendorNilReport.isLoading = true;
            break;
        case UPDATE_REPORT_DATA:
            updatedVendorNilReport.isUpdating = true;
            break;
        case SET_REPORT_DATA:
            updatedVendorNilReport.reportData = reportData as VendorNilData[];
            updatedVendorNilReport.lastRefreshedTimestamp = lastRefreshedTimestamp;
            updatedVendorNilReport.isLoading = false;
            updatedVendorNilReport.isUpdating = false;
            break;
        case TOGGLE_SHOW_UPDATE_COMPLETE_MODAL:
            updatedVendorNilReport.updateItemStatus = newItemStatus;
            updatedVendorNilReport.updateSuccessItems = successfulItems  as VendorNilData[];
            updatedVendorNilReport.updateFailedItems = failedItems as VendorNilData[];
            updatedVendorNilReport.showUpdateCompleteModal = !!(successfulItems.length || failedItems.length);
            break;
        default:
            break;
    }
    return { ...state, reportType, vendorNilReport: updatedVendorNilReport };
};

const VendorFuturePOReducer = (state: ImageryState, action: Action) => {
    const { type, reportType = '', reportData = [], lastRefreshedTimestamp = 0, successfulItems = [], failedItems = [], newItemStatus = '' } = action;

    let updatedVendorFuturePOReport = { ...state.vendorFuturePOReport };

    switch (type) {
        case REQUEST_REPORT_DATA:
            updatedVendorFuturePOReport.isLoading = true;
            break;
        case UPDATE_REPORT_DATA:
            updatedVendorFuturePOReport.isUpdating = true;
            break;
        case SET_REPORT_DATA:
            updatedVendorFuturePOReport.reportData = reportData as VendorFuturePOData[];
            updatedVendorFuturePOReport.lastRefreshedTimestamp = lastRefreshedTimestamp;
            updatedVendorFuturePOReport.isLoading = false;
            updatedVendorFuturePOReport.isUpdating = false;
            break;
        case TOGGLE_SHOW_UPDATE_COMPLETE_MODAL:
            updatedVendorFuturePOReport.updateItemStatus = newItemStatus;
            updatedVendorFuturePOReport.updateSuccessItems = successfulItems  as VendorFuturePOData[];
            updatedVendorFuturePOReport.updateFailedItems = failedItems as VendorFuturePOData[];
            updatedVendorFuturePOReport.showUpdateCompleteModal = !!(successfulItems.length || failedItems.length);
            break;
        default:
            break;
    }
    return { ...state, reportType, vendorFuturePOReport: updatedVendorFuturePOReport };
}

const StudioNilReducer = (state: ImageryState, action: Action) => {
    const { type, reportType = '', reportData = [], lastRefreshedTimestamp = 0, successfulItems = [], failedItems = [], newItemStatus = '' } = action;

    const studioNilReport = { ...state.studioNilReport };

    switch (type) {
        case REQUEST_REPORT_DATA:
            studioNilReport.isLoading = true;
            break;
        case UPDATE_REPORT_DATA:
            studioNilReport.isUpdating = true;
            break;
        case SET_REPORT_DATA:
            studioNilReport.reportData = reportData as StudioNilData[];
            studioNilReport.lastRefreshedTimestamp = lastRefreshedTimestamp;
            studioNilReport.isUpdating = false;
            studioNilReport.isLoading = false;
            break;
        case TOGGLE_SHOW_UPDATE_COMPLETE_MODAL:
            studioNilReport.updateItemStatus = newItemStatus;
            studioNilReport.updateSuccessItems = successfulItems  as StudioNilData[];
            studioNilReport.updateFailedItems = failedItems as StudioNilData[];
            studioNilReport.showUpdateCompleteModal = !!(successfulItems.length || failedItems.length);
            break;
        default:
            break;
    }
    return { ...state, reportType, studioNilReport };
}

const SupplierTrackerReducer = (state: ImageryState, action: Action) => {
    const { type, reportType = '', reportData = [], lastRefreshedTimestamp = 0, successfulItems = [], failedItems = [] } = action;

    const updatedSupplierTrackerReport = { ...state.supplierTrackerReport };

    switch (type) {
        case REQUEST_REPORT_DATA:
            updatedSupplierTrackerReport.isLoading = true;
            break;
        case UPDATE_REPORT_DATA:
            updatedSupplierTrackerReport.isUpdating = true;
            break;
        case SET_REPORT_DATA:
            updatedSupplierTrackerReport.reportData = reportData as SupplierTrackerData[];
            updatedSupplierTrackerReport.lastRefreshedTimestamp = lastRefreshedTimestamp;
            updatedSupplierTrackerReport.isUpdating = false;
            updatedSupplierTrackerReport.isLoading = false;
            break;
        case TOGGLE_SHOW_UPDATE_COMPLETE_MODAL:
            updatedSupplierTrackerReport.updateSuccessItems = successfulItems as SupplierTrackerData[];
            updatedSupplierTrackerReport.updateFailedItems = failedItems as SupplierTrackerData[];
            updatedSupplierTrackerReport.showUpdateCompleteModal = !!(successfulItems.length || failedItems.length);
            break;
        default:
            break;
    }
    return { ...state, reportType, supplierTrackerReport: updatedSupplierTrackerReport };
}

const FCPicklistReducer = (state: ImageryState, action: Action) => {
    const { reportType = '', type, recentReports } = action;

    let fcPicklist = { ...state.fcPicklist };

    switch (type) {
        case REQUEST_RECENT_REPORTS:
            fcPicklist.isLoading = true;
            break;
        case SET_RECENT_REPORTS:
            fcPicklist.recentFCReports = recentReports as FCReport[];
            fcPicklist.isLoading = false;
            break;
        case REQUEST_REPORT_GENERATION:
            fcPicklist.isUpdating = true;
            break;
        case SET_REPORT_GENERATION_REQUEST_IN_PROGRESS:
            fcPicklist.isUpdating = false;
            fcPicklist.isReportGenerationRequestSuccessful = true;
            break;
        case SET_REPORT_GENERATION_REQUEST_FAILURE:
            fcPicklist.isUpdating = false;
            fcPicklist.isReportGenerationRequestSuccessful = false;
            break;
        case RESET_REPORT_GENERATION_REQUEST_RESULT:
            fcPicklist.isReportGenerationRequestSuccessful = false;
            break;
        case REPORT_DOWNLOAD_IN_PROGRESS:
            fcPicklist.isReportDownloadInProgress = true;
            break;
        case REPORT_DOWNLOAD_SUCCESSFUL:
        case REPORT_DOWNLOAD_FAILURE:
            fcPicklist.isReportDownloadInProgress = false;
            break;
    }
    return { ...state, reportType, fcPicklist };
}

const ImagePullerReducer = (state: ImageryState, action: Action) => {
    const { reportType = '', type, recentImagePullerReports } = action;

    let imagePullerDetails = { ...state.imagePullerDetails };

    switch (type) {
        case REQUEST_RECENT_REPORTS:
            imagePullerDetails.isLoading = true;
            break;
        case SET_RECENT_REPORTS:
            imagePullerDetails.recentImagePullerReports = recentImagePullerReports as ImagePullerDetails[];
            imagePullerDetails.isLoading = false;
            break;
        case REQUEST_REPORT_GENERATION:
            imagePullerDetails.isUpdating = true;
            break;
        case SET_REPORT_GENERATION_REQUEST_IN_PROGRESS:
            imagePullerDetails.isUpdating = false;
            imagePullerDetails.isReportGenerationRequestSuccessful = true;
            break;
        case SET_REPORT_GENERATION_REQUEST_FAILURE:
            imagePullerDetails.isUpdating = false;
            imagePullerDetails.isReportGenerationRequestSuccessful = false;
            break;
        case RESET_REPORT_GENERATION_REQUEST_RESULT:
            imagePullerDetails.isReportGenerationRequestSuccessful = false;
            break;
    }
    return { ...state, reportType, imagePullerDetails };
}

const StatusHistoryReportReducer = (state: ImageryState, action: Action) => {
    const { reportType = '', type } = action;

    const updatedStatusHistoryReport = { ...state.statusHistoryReport };

    switch (type) {
        case REPORT_DOWNLOAD_IN_PROGRESS:
            updatedStatusHistoryReport.isReportDownloading = true;
            break;
        case REPORT_DOWNLOAD_SUCCESSFUL:
            updatedStatusHistoryReport.isReportDownloading = false;
            break;
        case REPORT_DOWNLOAD_FAILURE:
            updatedStatusHistoryReport.isReportDownloading = false;
            break;
        default:
            break;
    }
    return { ...state, reportType, statusHistoryReport: updatedStatusHistoryReport };
}

const ImageryReducer = (state = initialState, action: Action) => {
    switch (action.reportType) {
        case ReportType.VENDOR_NIL:
            return VendorNilReducer(state, action);
        case ReportType.VENDOR_FUTURE_PO_LIST:
            return VendorFuturePOReducer(state, action);
        case ReportType.STUDIO_NIL:
            return StudioNilReducer(state, action);
        case ReportType.SUPPLIER_TRACKER:
            return SupplierTrackerReducer(state, action);
        case ReportType.FC_PICKLIST:
            return FCPicklistReducer(state, action);
        case ReportType.IMAGE_PULLER:
            return ImagePullerReducer(state, action);
        case ReportType.STATUS_HISTORY:
            return StatusHistoryReportReducer(state, action);
        default:
            switch (action.type) {
                case SET_IMAGERY_USER_INFO:
                    const { userName = '', userDisplayName = '', userRole = '' } = action;
                    return { ...state, userName, userDisplayName, userRole, isAuthorisedToUpdate: userRole === IMAGERY_USERS_ROLE };
                default:
                    return state;
            }
    }
}

export default ImageryReducer;
