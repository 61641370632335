import React from 'react';
import ReactDOM from 'react-dom';
import {KatSpinner} from '@amzn/katal-react';

import App from './app/App';
import AppInitWrapper from './app/AppInitWrapper';
import './i18n';

import '@amzn/katal-components/styles.css';
import '@amzn/meridian-tokens/base/font/amazon-ember.css';
import './index.scss';
import {Provider} from "react-redux";
import store from "src/redux/Store";

ReactDOM.render(
    <React.StrictMode>/
        <AppInitWrapper>
            <React.Suspense fallback={<KatSpinner size='large'/>}>
                <Provider store={store}>
                    <App/>
                </Provider>
            </React.Suspense>
        </AppInitWrapper>
    </React.StrictMode>,
    document.getElementById('root')
);