export const STATUS_APPROVED = 'Approved';
export const STATUS_DECLINED = 'Declined';
export const STATUS_PENDING_REVIEW = 'pending';

export const GET_INFLUENCER_CONTENT_API = 'GET_INFLUENCER_CONTENT_API';
export const GET_INFLUENCER_APPROVAL_API = 'GET_INFLUENCER_APPROVAL_API';
export const UPDATE_INFLUENCER_APPROVAL_STATUS = 'UPDATE_INFLUENCER_APPROVAL_STATUS';
export const GET_INFLUENCER_PAYMENT_APPROVAL_API = 'GET_INFLUENCER_PAYMENT_APPROVAL_API';
export const UPDATE_INFLUENCER_PAYMENT_STATUS_API = 'UPDATE_INFLUENCER_PAYMENT_STATUS_API';
export const SUCCESS = 'SUCCESS';
export const FAILED = 'FAILED';
export const FETCHING = 'FETCHING';
export const APPROVE = "Approve";
export const DENY = "Deny";
export const DECLINE = "decline";
