import {CatalogState} from "src/redux/State";
import {
  CATALOG_USERS_READONLY_ROLE,
  CATALOG_USERS_ROLE,
  OperationSubType,
  OperationType,
  SET_CATALOG_USER_INFO
} from "src/constants/catalog/CatalogConstants";
import {CatalogAPIStatus} from "src/types/catalog/Catalog";

export const initialState: CatalogState = {
  userName: '',
  userDisplayName: '',
  userRole: '',
  isAuthorisedToUpdate: false,
  isAuthorisedToRead: false,
  isLoading: false,
  supplierFlipHistory: {
    apiStatus: CatalogAPIStatus.UNKNOWN,
    data: []
  },
  supplierFlipInput: {
    apiStatus: CatalogAPIStatus.UNKNOWN,
  },
  catalogHealthToolState: {
    apiStatus: CatalogAPIStatus.UNKNOWN,
    data: [],
    filterAttributeValues: {}
  }
}

interface Action {
  type: string;
  operationType?: OperationType;
  operationSubType?: OperationSubType;
  apiStatus?: CatalogAPIStatus;
  data?: any;
  userName?: string;
  userRole?: string;
}

const SupplierFlipperReducer = (state: CatalogState, action: Action) => {
  const {type, operationSubType, operationType, apiStatus, data} = action;

  switch (operationSubType) {
    case OperationSubType.SUPPLIER_FLIPPER_HISTORY:
      return {...state, supplierFlipHistory: {apiStatus, data}}
    case OperationSubType.SUPPLIER_FLIPPER_INPUT:
      return {...state, supplierFlipInput: {apiStatus}}
    default:
      return state;
  }
}

const CatalogHealthToolReducer = (state: CatalogState, action: Action) => {
  const {operationSubType, apiStatus, data} = action;

  switch (operationSubType) {
    case OperationSubType.FETCH_CATALOG_VIEW_DATA:
      return { ...state, catalogHealthToolState: { apiStatus, data } }
    case OperationSubType.FETCH_CATALOG_VIEW_FILTERS:
      return { ...state, catalogHealthToolState: { apiStatus, filterAttributeValues: data } };
    default:
      return state;
  }
}

const CatalogReducer = (state = initialState, action: Action) => {

  switch (action.operationType) {
    case OperationType.SUPPLIER_FLIPPER:
      return SupplierFlipperReducer(state, action);
    case OperationType.CATALOG_HEALTH_TOOL:
      return CatalogHealthToolReducer(state, action);
    default:
      switch (action.type) {
        case SET_CATALOG_USER_INFO:
          const {userName = '', userRole = ''} = action;
          let isAuthorisedToRead = false;
          let isAuthorisedToUpdate = false;
          if (userRole === CATALOG_USERS_ROLE) {
            isAuthorisedToRead = true;
            isAuthorisedToUpdate = true;
          } else if (userRole === CATALOG_USERS_READONLY_ROLE) {
            isAuthorisedToRead = true;
          }
          return {...state, userName, userRole, isAuthorisedToUpdate, isAuthorisedToRead};
        default:
          return state;
      }
  }
}

export default CatalogReducer;
