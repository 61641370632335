import {Map} from "src/types/Imagery"

export const ZAPPOS_LOGO = 'https://m.media-amazon.com/images/G/01/zappos/imageryservice/zappos_logo.png';
export const VI_DASHBOARD_URL = 'https://us-east-1.quicksight.aws.amazon.com/sn/auth/signin?qs-signin-user-auth=false&redirect_uri=https%3A%2F%2Fus-east-1.quicksight.aws.amazon.com%2Fsn%2Fdashboards%2F5bb234d4-b474-4a54-a42d-1806de45b7f7%2Fsheets%2F5bb234d4-b474-4a54-a42d-1806de45b7f7_af3c0a90-ce99-4828-ae2f-d136ffae555a%3Fqs-signin-user-auth%3Dfalse%26state%3DhashArgs%2523%26isauthcode%3Dtrue';
export const ITEMS_PER_PAGE_OPTIONS = [20, 50, 100];
export const MAX_UPDATE_COUNT_ITEM_STATUS = 500;
export const MAX_UPDATE_STYLE_COUNT = 50;
export const MAX_STYLE_IDS_IMAGERY_PULLER = 300;
export const MAX_BATCH_NAME_LENGTH = 100;
export const SELECT_PLACEHOLDER = 'Select...';
export const MAX_ALLOWED_DATE_DIFF_IN_DAYS = 15;

// Dashboard Enums
export enum SIDE_MENU_GROUP {
    NONE, // Placeholder for none selection
    VENDOR_IMAGERY,
    STUDIO_IMAGERY,
    MERCHANDISING,
    IMAGE_PULLER,
    REPORTING,
}

export enum SIDE_MENU_OPTION {
    NONE, // Placeholder for none selection
    FUTURE_PO_VI,
    VI_NO_IMAGE_LIST,
    SI_NO_IMAGE_LIST,
    FC_REPORTS,
    VI_SUPPLIER_TRACKER,
    IMAGE_PULLER,
    STATUS_HISTORY,
}

export enum SORT_DIRECTION {
    ASCENDING = "ascending",
    DESCENDING = "descending"
}

export enum ReportType {
    VENDOR_NIL = "VENDOR_NIL",
    VENDOR_FUTURE_PO_LIST = "VENDOR_FUTURE_PO_LIST",
    STUDIO_NIL = "STUDIO_NIL",
    SUPPLIER_TRACKER = "SUPPLIER_TRACKER",
    FC_PICKLIST = "FC_PICKLIST",
    IMAGE_PULLER = "IMAGE_PULLER",
    STATUS_HISTORY = "STATUS_HISTORY"
}

export enum FC_REPORT_STATUS {
    COMPLETED = 'COMPLETED',
    IN_PROGRESS = 'IN_PROGRESS',
    FAILED = 'FAILED'
}

// VI Status mapping
export const VI_STATUS = {
    VI_INITIATED: 'VI Initiated',
    VI_IN_PROGRESS: 'VI In Progress',
    VI_FAILED: 'VI Failed',
} as Map;

export const STUDIO_PICKED_WORLDSHIP_KEY = 'STUDIO_PICKED_WORLDSHIP';
export const STUDIO_PICKED_TRANSHIP_KEY = 'STUDIO_PICKED_TRANSHIP';
export const STUDIO_PICKED_KEY = 'STUDIO_PICKED';

// Studio NIL Status mapping
export const STUDIO_NIL_STATUS = {
    STUDIO_PICKED_WORLDSHIP: 'Studio Picked - Worldship',
    STUDIO_PICKED_TRANSHIP: 'Studio Picked - Tranship',
    STUDIO_PICKED: 'Studio Picked',
    STUDIO_IN_PROGRESS: 'Studio In progress',
    NO_ACTION: 'No action',
    PROBLEM_SOLVING: 'Problem Solving'
} as Map;

export const IMAGERY_ITEM_STATUS = {...VI_STATUS, ...STUDIO_NIL_STATUS};

export const FUTURE_PO_STYLE_ALL_STATUS = 'ALL';
export const FUTURE_PO_STYLE_NOT_AVAILABLE_STATUS = 'N/A';

export const IMAGERY_ITEM_STATUS_COLOR_MAP = {
    'VI Initiated': '#d4effe',
    'VI In Progress': '#f9f1cf',
    'VI Failed': '#ffe3e3',
    'Studio Picked - Worldship': '#cbc3e3',
    'Studio Picked - Tranship': '#eacab8',
    'Studio Picked': '#a9b4e7',
    'Studio In progress': '#cddec5',
    'Problem Solving': '#dfe1df'
} as Map;

// Supplier Data Options
export const VI_CAPABLE = ['Y', 'N'];

export const IMAGERY_PREFERENCE = ['Vendor Image', 'Sample Image', 'Photo PO', 'FC Pick', 'Not Selected'];

export const LAST_UPDATED_AT_COLUMN_KEY = "lastUpdatedTimeString";
export const FC_REPORT_DATE_COLUMN_KEY = "requestTimestamp";
export const FC_REPORT_DOWNLOAD_COLUMN_KEY = "link";
export const STYLE_STATUS_ELEMENT_COLUMN_KEY = "styleStatusElement";

export const UNDEFINED_SORT_COLUMNS = [
    FC_REPORT_DOWNLOAD_COLUMN_KEY,
    STYLE_STATUS_ELEMENT_COLUMN_KEY
];

export const DATE_TIME_COLUMNS_FOR_CUSTOM_SORT = [
    LAST_UPDATED_AT_COLUMN_KEY,
    FC_REPORT_DATE_COLUMN_KEY
];

export const SUPPLIER_STATUS = [
    'N/A',
    'Outreach Needed',
    'Outreach in Progress',
    'Outreach Complete - Updated to VI',
    'Outreach Complete - Not VI Capable',
    'Removed From VI',
];

export const REASON_FOR_NON_VI = [
    'N/A',
    'Unable to meet tech specs',
    'Unable to meet imagery guidelines',
    'No in-house resources',
    'Does not image all styles',
    'Needs lead time for in-house resources',
    'Needs lead time - follow up in 1 month',
    'Needs lead time - follow up in 2 months',
    'Needs lead time - follow up in 3 months',
];

//StudioNIL report options
export const PRODUCT_CATEGORY: Record<string, string> = {
    ALL: 'All',
    ADULT_APPAREL: 'Adult Apparel',
    KIDS_APPAREL: 'Kids Apparel',
    SHOES: 'Shoes',
    OTHERS: 'Others',
};

export const IMAGE_QUEUE_OPTIONS: Record<string, string> = {
    VENDOR_IMAGERY: 'VendorImagery',
    STUDIO_IMAGERY: 'StudioImagery',
};

export const PRODUCT_TYPE = {
    CLOTHING: 'Clothing',
    SHOES: 'Shoes',
};

export const IS_KIDS = {
    YES: "Yes",
    NO: "No",
};

// Report constants
export const VI_NIL_PAGE = {
    HEADING: 'VI No-Image-List',
    CAPTION: 'VI styles that have arrived to an FC missing images. Actioned by VI and Studio Teams to collect images on day 1 of PO receipt date.',
    FOOTER: 'Showing all missing image Stocks of VI suppliers that are either received already or have Start Ship Date of T+30 days.',
    PRIMARY_COLUMN: 'styleId',
    DEFAULT_SORT_COLUMN: 'supplierName',
    REPORT_FILE_NAME: 'Vendor Imagery No Image List',
};

export const VI_FUTURE_PO_PAGE = {
    HEADING: 'Future POs for Vendor Imagery',
    CAPTION: 'Inbound POs from VI eligible suppliers containing new styles needing images. Actioned by VI Team to retrieve images before PO receipt date.',
    FOOTER: 'Showing all POs of VI suppliers that have Start Ship Date of T+30 days.',
    PRIMARY_COLUMN: 'poId',
    PRIMARY_COLUMN_STYLE_ID: 'styleId',
    DEFAULT_SORT_COLUMN: 'poStartShipDateString',
    REPORT_FILE_NAME: 'Future POs for Vendor Imagery',
};

export const STUDIO_NIL_PAGE = {
    HEADING: 'Studio No-Image-List',
    FOOTER: 'Showing all missing image Stocks that are received in the FC/Studio.',
    PRIMARY_COLUMN: 'productName',
    DEFAULT_SORT_COLUMN: 'firstReceivedDate',
    REPORT_FILE_NAME: 'Studio Imagery No Image List',
};

export const FC_REPORTS_PAGE = {
    HEADING: 'Recent FC Picklist Reports',
    FOOTER: 'Showing records for FC requests submitted within the past 30 days',
    PRIMARY_COLUMN: 'fileName',
    DEFAULT_SORT_COLUMN: 'requestTimestamp',
    CAPTION: 'Showing recently submitted FC Picklist reports.'
};

export const IMAGE_PULLER_PAGE = {
    HEADING: 'Recent Submissions',
    FOOTER: 'Showing records from the past 30 days',
    PRIMARY_COLUMN: 'batchName',
    DEFAULT_SORT_COLUMN: 'requestTimestamp'
};

export const SUPPLIER_TRACKER_PAGE = {
    HEADING: 'VI Supplier Capability Tracker',
    CAPTION: 'Supplier image capability and performance database. Used by VI and Merch Teams to track supplier status and VI participation.',
    FOOTER: 'Showing all Suppliers with their VI capability and performance status.',
    PRIMARY_COLUMN: 'supplierId',
    SECONDARY_COLUMN: 'supplierName',
    DEFAULT_SORT_COLUMN: 'buyerName',
    REPORT_FILE_NAME: 'Vendor Imagery Supplier Tracker',
};

export const STYLE_SHEET = {
    HEADING: 'Style Status',
    PRIMARY_COLUMN: 'styleId',
    DEFAULT_SORT_COLUMN: 'styleId',
    REPORT_FILE_NAME: 'Style Sheet',
    DEFAULT_PAGE_SIZE: 100,
};

export const STATUS_HISTORY_REPORT_PAGE = {
    HEADING: 'Generate Report',
    CAPTION: 'Generate a report of all styles that have exited the imagery process (i.e., styles having Last Img Upload date OR VI Failed date) within the specified date range.',
    DEFAULT_DATE_RANGE_DIFF_IN_DAYS: [7, 15],
};

// Column name mappings
export const VI_FUTURE_PO_COLUMNS = {
    poId: 'PO ID',
    poStartShipDateString: 'First Start Ship',
    styleCount: 'Style Count',
    supplierName: 'Supplier Name',
    buyerCategory: 'Buyer Category',
    buyerName: 'Buyer Name',
    productType: 'Product Type',
    productCategory: 'Product Category',
    vendorPreferredImagePath: 'Vendor Preferred Image Path',
    poStatus: 'PO status',
    poLevelUpdateUsers: 'Last Updated By',
    styleStatusElement: 'Style Status',
};

export const VI_NIL_COLUMNS = {
    productName: 'Product Name',
    brandName: 'Brand Name',
    supplierName: 'Supplier Name',
    productType: 'Product Type',
    productCategory: 'Product Category',
    productSubCategory: 'Product Subcategory',
    styleId: 'Style Id',
    productId: 'Product Id',
    stockId: 'Stock Id',
    quantity: 'Quantity',
    gender: 'Gender',
    color: 'Color',
    persona: 'Personas',
    isKids: 'Is Kids?',
    supplierId: 'Supplier Id',
    firstReceivedDate: 'First Received Date',
    supplierType: 'Supplier Type',
    hasVendorImage: 'Has Vendor Img?',
    marketplaceId: 'Marketplace Id',
    merchantId: 'Merchant Id',
    itemStatus: 'VI Status',
    lastUpdateUser: 'Last Updated By',
    lastUpdatedTimeString: 'Last Updated At (PST)',
};

export const STUDIO_NIL_COLUMNS = {
    productName: 'Product Name',
    brandName: 'Brand Name',
    supplierName: 'Supplier Name',
    productType: 'Product Type',
    productCategory: 'Product Category',
    productSubCategory: 'Product Sub Category',
    isKids: 'Is Kids?',
    styleId: 'Style Id',
    stockId: 'Stock Id',
    productId: 'Product Id',
    asinString: 'ASIN',
    warehouseIdString: 'Warehouse Id',
    upc: 'UPC',
    gender: 'Gender',
    size: 'Size',
    width: 'Width',
    inseam: 'Inseam',
    color: 'Color',
    persona: 'Personas',
    hasVendorImage: 'Has Vendor Image?',
    firstReceivedDate: 'First Received Date',
    quantity: 'Quantity',
    supplierType: 'Business Unit',
    marketplaceId: 'Marketplace Id',
    merchantId: 'Merchant Id',
    rackStatus: 'Rack Manager Status',
    itemStatus: 'Status',
    lastUpdateUser: 'Last Updated By',
    lastUpdatedTimeString: 'Last Updated At (EST)',
};

export const FC_REPORTS_COLUMNS = {
    fileName: 'File Name',
    warehouseId: 'Warehouse Id',
    requestTimestamp: 'Report Date',
    requestOwner: 'Request Owner',
    link: 'CSV File',
    reportStatus: 'Report Status'
};

export const IMAGE_PULLER_COLUMNS = {
    batchName: 'Batch Name',
    totalStyles: 'Total Styles',
    imageryType: 'Imagery Type',
    requestTimestamp: 'Report Date',
    requestOwner: 'Request Owner'
};

export const SUPPLIER_TRACKER_COLUMNS = {
    supplierName: 'Brand (Supplier)',
    supplierId: 'Supplier Id',
    buyerName: 'Buyer(s)',
    buyerCategory: 'Buyer Category',
    businessUnit: 'Business Unit',
    viCapable: 'VI Capable',
    photoPOEligible: 'Photo PO Enabled?',
    vendorPreferredImagePath: 'Imagery Preference',
    viCoverage: 'VI Coverage',
    viRejection: 'VI Rejection',
    supplierStatus: 'Status',
    reasonForNonVI: 'Reason For Non-VI',
    lastUpdateUser: 'Last Updated By',
    lastUpdatedTimeString: 'Last Updated At (PST)',
};

export const STYLE_SHEET_COLUMNS = {
    styleId: 'StyleId',
    styleNumber: 'Style Number',
    color: 'Color',
    productId: 'SKU',
    productName: 'Product Name',
    productType: 'Product Type',
    productCategory: 'Product Category',
    supplierName: 'Supplier',
    styleStatus: 'Style Status',
    lastUpdatedUser: 'Last Updated By',
    lastUpdatedTimeString: 'Last Updated At (PST)',
};

// Action Constants
export const SET_IMAGERY_USER_INFO = 'SET_IMAGERY_USER_INFO';
export const REQUEST_REPORT_DATA = 'REQUEST_REPORT_DATA';
export const SET_REPORT_DATA = 'SET_REPORT_DATA';
export const UPDATE_REPORT_DATA = 'UPDATE_REPORT_DATA';
export const TOGGLE_SHOW_UPDATE_COMPLETE_MODAL = 'TOGGLE_SHOW_UPDATE_COMPLETE_MODAL';

// Picklist Action Constants
export const REQUEST_RECENT_REPORTS = "REQUEST_RECENT_REPORTS";
export const SET_RECENT_REPORTS = "SET_RECENT_REPORTS";
export const REPORT_DOWNLOAD_IN_PROGRESS = "REPORT_DOWNLOAD_IN_PROGRESS";
export const REPORT_DOWNLOAD_SUCCESSFUL = "REPORT_DOWNLOAD_SUCCESSFUL";
export const REPORT_DOWNLOAD_FAILURE = "REPORT_DOWNLOAD_FAILURE";
export const REQUEST_REPORT_GENERATION = "REQUEST_REPORT_GENERATION";
export const SET_REPORT_GENERATION_REQUEST_IN_PROGRESS = "SET_REPORT_GENERATION_REQUEST_IN_PROGRESS";
export const SET_REPORT_GENERATION_REQUEST_FAILURE = "SET_REPORT_GENERATION_REQUEST_FAILURE";
export const RESET_REPORT_GENERATION_REQUEST_RESULT = "RESET_REPORT_GENERATION_REQUEST_RESULT";
export const ASIN_REGEX_PATTERN = /^[a-zA-Z0-9]{10}$/;
export const FC_WAREHOUSE_ID_REGEX_PATTERN = /^(ZAP1|ZAP2|SDF4|SDF8|FTW9|ABE3|MDT2|IND4)$/;
export const MAX_ALLOWED_NUMBER_OF_ASINS_FOR_FC_REPORT_GENERATION = 300;

//Imagery puller constants
export const DIGITS_ONLY_REGEX = /^\d+$/;
export const BATCH_NAME_REGEX = /^[a-zA-Z0-9_-]+$/

// Roles
export const IMAGERY_USERS_ROLE = 'CN=zappos-imagery-users,OU=Security Groups,OU=Las Vegas,OU=Zappos2010,DC=corp,DC=zappos,DC=com';
export const IMAGERY_USERS_READONLY_ROLE = 'CN=zappos-imagery-users-readonly,OU=Security Groups,OU=Las Vegas,OU=Zappos2010,DC=corp,DC=zappos,DC=com';

export const EST_TIMEZONE_STRING = 'America/New_York';
export const PST_TIMEZONE_STRING = 'America/Los_Angeles';
