import {AkitaAPIState} from "src/redux/State";
import {
    AkitaAPIType,
    APIStatus,
    BrandPromoTablesAPIStatus,
    OrderPromoTablesAPIStatus,
    PromoCategory,
    PromoTablesAPIStatus
} from "src/types/Akita";

const initialState: AkitaAPIState = {
    getCustomerAPIStatus: APIStatus.UNKNOWN,
    getBrandPromotionsAPIStatus: {
        [PromoCategory.ACTIVE]: APIStatus.UNKNOWN,
        [PromoCategory.ALL]: APIStatus.UNKNOWN
    },
    getOrderPromotionsAPIStatus: {
        [PromoCategory.UPCOMING]: APIStatus.UNKNOWN,
        [PromoCategory.ACTIVE]: APIStatus.UNKNOWN,
        [PromoCategory.ALL]: APIStatus.UNKNOWN
    },
    getPromotionsAPIStatus: {
        [PromoCategory.ACTIVE]: APIStatus.UNKNOWN,
        [PromoCategory.ALL]: APIStatus.UNKNOWN
    },
    getCustomerOperationAPIStatus: APIStatus.UNKNOWN,
    getTurnoverReviewAPIStatus: APIStatus.UNKNOWN,
    getFileUploadAPIStatus:APIStatus.UNKNOWN
}

interface Action {
    type?: AkitaAPIType;
    apiStatus?: APIStatus;
    promoCategory?: PromoCategory;
    getOrderPromotionsAPIStatus?: OrderPromoTablesAPIStatus;
    getBrandPromotionsAPIStatus?: BrandPromoTablesAPIStatus;
    getPromotionsAPIStatus?: PromoTablesAPIStatus;
}

const AkitaAPIReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case AkitaAPIType.AKITA_GET_CUSTOMER_DETAILS_API:
            return {
                ...state,
                getCustomerAPIStatus: action.apiStatus
            };
        case AkitaAPIType.AKITA_GET_PROMOTIONS_API:
            return {
                ...state,
                getPromotionsAPIStatus: action.getPromotionsAPIStatus
            };
        case AkitaAPIType.AKITA_GET_BRAND_PROMOTIONS_API:
            return {
                ...state,
                getBrandPromotionsAPIStatus: action.getBrandPromotionsAPIStatus
            };
        case AkitaAPIType.AKITA_GET_ORDER_PROMOTIONS_API:
            return {
                ...state,
                getOrderPromotionsAPIStatus: action.getOrderPromotionsAPIStatus
            };
        case AkitaAPIType.AKITA_GET_CUSTOMER_OPERATIONS_API:
            return {
                ...state,
                getCustomerOperationAPIStatus: action.apiStatus
            };
        case AkitaAPIType.AKITA_GET_TURNOVER_API:
            return {
                ...state,
                getTurnoverReviewAPIStatus: action.apiStatus
            };
        case AkitaAPIType.AKITA_GET_FILE_UPLOAD_API:
            return {
                ...state,
                getFileUploadAPIStatus: action.apiStatus
            };
        default:
            return state;
    }
}
export default AkitaAPIReducer;
