import CookieService from "src/utils/CookieService";
import {Buffer} from "buffer";
import {AdfsTokenResponse, getAdfsToken, isSessionInvalid} from "src/utils/Authentication";
import {removeItemFromLocalStorage, saveItemToLocalStorage} from "src/utils/LocalStorageUtils";

export async function setAdfsSessionCookies(refreshToken: string) : Promise<boolean> {
    return getAdfsToken(refreshToken).then((token: AdfsTokenResponse) => {
        if (Object.keys(token).length !== 0) {
            setAdfsSessionCookiesHelper(token);
            return true;
        }
        return false;
    });
}

export function setAdfsSessionCookiesHelper(tokenResponse: AdfsTokenResponse) {
    const expiryTimestamp = tokenResponse['expiryTimestamp' as keyof AdfsTokenResponse] || 0;
    const expiresIn = tokenResponse['expiresIn'] || 0;
    if (expiryTimestamp == 0 || isSessionInvalid(tokenResponse['accessToken'] || '')) {
        console.log('Invalid session details found in adfs token : ' + tokenResponse['accessToken']);
        return;
    }
    handleCookie('adfs-access-token', tokenResponse['accessToken'] || '', expiresIn);
    if (tokenResponse['refreshToken' as keyof AdfsTokenResponse]) {
        handleCookie('adfs-refresh-token', tokenResponse['refreshToken'] || '', tokenResponse['refreshTokenExpiresIn'] || 0);
    }
}

export function handleCookie(cookieName: string, cookieValue: string, expiresIn: number) {
    const size = Buffer.byteLength(cookieValue, 'utf8');
    if (size > 4000) {
        saveItemToLocalStorage(cookieName, cookieValue, expiresIn);
    } else {
        const expiresInMins = expiresIn * 1000;
        let date = new Date();
        date.setTime(date.getTime() + expiresInMins);
        const options = { path: '/', expires: date, sameSite: 'Lax', secure: true, domain: '.zappos.biz' };
        CookieService.set(cookieName, cookieValue, options);
    }
}

export function resetAdfsCookiesAndLocalStorage() {
    const options = { path: '/', sameSite: 'Lax', secure: true, domain: '.zappos.biz' };
    CookieService.remove('adfs-access-token', options);
    removeItemFromLocalStorage('adfs-access-token');
}