import {AkitaUserInfoState} from "src/redux/State";
import {
    AKITA_CONFIG_ROLE,
    AKITA_CONFIG_ROLE_IDENTIFIER,
    AKITA_DEFAULT_ROLE,
    AKITA_DEV_ROLE,
    AKITA_DEV_ROLE_IDENTIFIER, AKITA_USERNAME
} from "src/constants/AkitaDashboard/common";

const initialState: AkitaUserInfoState = {
    role: AKITA_DEFAULT_ROLE,
    userName: ""
}

interface Action {
    type: String,
    userName: string
}

const AkitaUserInfoReducer = (state= initialState, action: Action) => {
    switch (action.type) {
        case AKITA_DEV_ROLE_IDENTIFIER:
            return { ...state, role: AKITA_DEV_ROLE };
        case AKITA_CONFIG_ROLE_IDENTIFIER:
            return { ...state, role: AKITA_CONFIG_ROLE };
        case AKITA_USERNAME:
            return { ...state, userName: action.userName };
        default:
            return state;
    }
}

export default AkitaUserInfoReducer;
