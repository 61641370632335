export const FLIP_TYPE_GROUP = 'FLIP TYPE GROUP';

export const DOWNLOAD_SAMPLE_BUTTON_TEXT = "Sample Template";
export const UPLOAD_BUTTON_TEXT = "Upload .xlsx File";
export const SUPPLIER_FLIP_BUTTON_TEXT = "Flip Supplier";

interface flipTypeDetails {
  value: string;
  path: string;
}

export enum FLIP_TYPE {
  WHOLESALE_TO_DF = "WHOLESALE_TO_DF",
  DF_TO_WHOLESALE = "DF_TO_WHOLESALE",
  DF_TO_DF = "DF_TO_DF",
  WHOLESALE_TO_WHOLESALE = "WHOLESALE_TO_WHOLESALE"
}

export const FLIP_TYPE_DETAILS = new Map<FLIP_TYPE, flipTypeDetails>();
FLIP_TYPE_DETAILS.set(FLIP_TYPE.WHOLESALE_TO_DF, {value: 'Wholesale to DF', path: 'wholesaleToDF'});
FLIP_TYPE_DETAILS.set(FLIP_TYPE.DF_TO_WHOLESALE, {value: 'DF to Wholesale', path: 'dfToWholesale'});
FLIP_TYPE_DETAILS.set(FLIP_TYPE.DF_TO_DF, {value: 'DF to DF', path: 'dfToDF'});
FLIP_TYPE_DETAILS.set(FLIP_TYPE.WHOLESALE_TO_WHOLESALE, {value: 'Wholesale to Wholesale', path: 'wholesaleToWholesale'});

// validation
export const InputFileColumnMapping = {
  stockId: "STOCKID",
  asin: "ASIN",
  newSupplierId: "New Supplier Id",
  newVendorCode: "New Vendor Code",
  oldSupplierId: "Old Supplier Id",
  oldVendorCode: "Old Vendor Code",
}
export const inputFileColumns: string[] = Object.values(InputFileColumnMapping);
export const VENDOR_CODE_LENGTH = 5;
export const FLIP_INPUT_RECORDS_LIMIT = 1500;

// Api status for supplier flip
export enum SUPPLIER_FLIP_API_STATUS {
  NONE = "SUPPLIER_FLIP_STATUS_NONE",
  SUCCESS = "SUPPLIER_FLIP_STATUS_SUCCESS",
  FAILURE = "SUPPLIER_FLIP_STATUS_FAILURE",
  FETCHING = "SUPPLIER_FLIP_STATUS_FETCHING"
}

export const SUPPLIER_FLIPS_REPORT = {
  HEADING: 'Recent Flip Reports',
  FOOTER: 'Showing files submitted to flip Suppliers within the past 30 days',
  PRIMARY_COLUMN: 'fileName',
  DEFAULT_SORT_COLUMN: 'requestTimestamp',
  CAPTION: 'Showing recently submitted Supplier Flip reports.'
};

export const SF_REPORTS_COLUMNS = {
  fileName: 'File Name',
  totalASINs: 'Total ASINs',
  completedASINs: 'Completed ASINs',
  reportDate: 'Report Date',
  requestOwner: 'Request Owner',
  downloadButton: 'CSV File',
  reportStatus: 'Report Status'
};
