import {FxmState} from "src/redux/State";
import {APIState, FXM_LOGIN_SUCCESSFUL, FXM_LOGIN_FAILED, FxmAPIType} from "src/types/Fxm";

const initialState: FxmState = {
    loggedIn: false,
    fetchCancelOrderFileUploadPresignedUrl:APIState.UNKNOWN,
    putCancelOrderFileUploadFXM:APIState.UNKNOWN,
    putCancelOrderFileUploadZFT:APIState.UNKNOWN,
    timeLeftCancelOrderSubmitButtonZappos: 0,
    timeLeftCancelOrderSubmitButtonSIXPM: 0,
}

interface Action {
    type?: FxmAPIType | string;
    apiStatus?: APIState;
    apiValue?: number;
}

const FXMReducer = (state = initialState, action: Action)=>{
    switch (action.type){
        case FXM_LOGIN_SUCCESSFUL:
            return { ...state, loggedIn: true };
        case FXM_LOGIN_FAILED:
            return { ...state, loggedIn: false };
        case FxmAPIType.FETCH_CANCEL_ORDER_FILE_UPLOAD_PRESIGNEDURL:
            return {
                ...state,
                fetchCancelOrderFileUploadPresignedUrl: action.apiStatus
            };
        case FxmAPIType.PUT_CANCEL_ORDER_FILE_UPLOAD_FXM:
            return {
                ...state,
                putCancelOrderFileUploadFXM: action.apiStatus
            };
        case FxmAPIType.PUT_CANCEL_ORDER_FILE_UPLOAD_ZFT:
            return {
                ...state,
                putCancelOrderFileUploadZFT: action.apiStatus
            };
        case FxmAPIType.GET_CANCEL_ORDER_FILEUPLOAD_SUBMITBUTTON_ZAPPOS:
            return {
                ...state,
                timeLeftCancelOrderSubmitButtonZappos: action.apiValue
            };
        case FxmAPIType.GET_CANCEL_ORDER_FILEUPLOAD_SUBMITBUTTON_SIXPM:
            return {
                ...state,
                timeLeftCancelOrderSubmitButtonSIXPM: action.apiValue
            };
        default: return state;
    }
}

export default FXMReducer;
