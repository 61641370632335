export const OUTFITS_USERNAME = "OUTFITS_USERNAME";

export const USERS_WITH_MODERATOR_ACCESS = ["ayuskhan-amzn", "shaunakv-amzn", "shatrun-amzn", "pawish-amzn", "aniketsv-amzn", "sagameht-amzn", "aspimpar-amzn"];

export const ALERT_SUCCESS = "success";

export const ALERT_ERROR = "error";

export const PENDING_REVIEW_STATUS = "PENDING";

export const DISCARD_REVIEW_STATUS = "DISCARDED";

export const RESOLVE_REVIEW_STATUS = "REVIEWED";

export const OUTFIT_FEEDBACK_REPORTS = "OUTFIT_FEEDBACK_REPORTS";

export const OUTFIT_RECOMMENDATIONS = "OUTFIT_RECOMMENDATIONS";

export const MSA_BASE_URL = "https://m.media-amazon.com/images/I/";

export const BROWSE_NODE_SEARCH_ASIN_BASE_URL = "https://browse-whereismyasin-na-iad.iad.proxy.amazon.com/WhereIsMyAsinAction.do?marketplaceID=1&asin=";

export const SKYMALL_SEARCH_STYLE_BASE_URL = "https://skymall.zappos.biz/style/";

export const ZAPPOS_MARKETPLACE_PATH_PARAM = "/1";

export const INCLUDE_OUT_OF_STOCK_QUERY_PARAM = "?includeOos=true"

export const SET_OUTFITS_API_STATUS = "SET_OUTFITS_API_STATUS";

export const BLOCK_AT_SYMBOL_URL_PATTERN = "^[^@]*$"

export const VALID_PRODUCT_URL_PATTERN = "/product/([^/]+)/color/([^/?]+)";

export const OUTFITS_API_PREFIX = "/outfits";
